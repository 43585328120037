import { ref, computed } from 'vue'
import { useCluberLocalStorage } from '@cluber/tools-composables'
import { type ApiAuthStoreShape, type LocalStorageAuth } from '@services/Auth/declarations'
import { useApiStore, isJWTExpired, axiosInstance } from '@core/store/apiRequest/apiRequest'

export const useApiAuthStore = useApiStore<'apiAuth', ApiAuthStoreShape>('apiAuth')

const { storage } = useCluberLocalStorage<Omit<LocalStorageAuth, 'currentProfile'>>({
  auth: { token: '', userIdentifier: '' },
}, { mergeDefaults: true })

export function manageUserAuth() {
  const apiAuthStore = useApiAuthStore()
  const isAuthenticated = ref(false)
  const currentUserToken = computed(() => apiAuthStore.$state.postCreateUserToken?.token || storage.value.auth.token)

  async function initializeUserAuth() {
    if (currentUserToken.value) {
      // @ts-expect-error no hydra data needed
      apiAuthStore.$state.postCreateUserToken = {
        token: currentUserToken.value,
        refresh_token: '',
      }
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${currentUserToken.value}`
      if (!isJWTExpired(currentUserToken.value)) {
        isAuthenticated.value = isJWTExpired(currentUserToken.value)
      }
    }
  }

  return {
    isAuthenticated,
    initializeUserAuth,
    currentUserToken,
  }
}
