<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import { ExcludeExternalRole } from '@libs/fsc/types/user'
import { translationKeyByRole } from '@libs/fsc/utils/roles'

import { UserSupportFormParameters } from '@dashboard/components/ZohoFormContainer/declarations'
import ZohoFormContainer from '@dashboard/components/ZohoFormContainer/ZohoFormContainer.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const { currentUser } = useGetLegacyPeopleGroupByClub()

const resolvedRoute = router.resolve({ name: 'managerOverview', params: { clubHash: route.params.clubHash } })
const userRole = route.meta.roleKind as ExcludeExternalRole

const userParams = computed(() => {
  const user = currentUser.value
  if (!user) return undefined

  const userFields : UserSupportFormParameters = {
    clubName: user.clubName || '',
    clubId: route.params.clubHash as string || '',
    email: user.email || '',
    clubUrl: resolvedRoute.fullPath,
    phone: user.phone || '',
    name: `${user.name} ${user.lastname}`,
    personId: user.id || '',
    role: t(translationKeyByRole(userRole)),
  }

  return userFields
})
</script>

<template>
  <div>
    <h3 class="font-display font-semibold text-3xl mb-8">
      {{ t('Notify an incidence') }}
    </h3>
    <ZohoFormContainer
      class="h-[56rem]"
      form-route="incidence-form"
      :params="userParams"
    />
  </div>
</template>
