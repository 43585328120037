<script setup lang="ts">

import {
  computed,
  reactive,
  onBeforeMount,
} from 'vue'
import { useI18n } from 'vue-i18n'

import {
  CbPhone,
  CbButton,
} from '@cluber/carabiner'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import { usePhoneCode } from '@core/modules/User/composables/usePhoneCode'
import { useApiMessagesStoreRequest } from '@services/Communications/request'

// ***
// DEFAULTS
// ***
const props = defineProps({
  subject: {
    type: String,
    required: true,
  },
  clubHash: {
    type: String,
    required: true,
  },
  isExternal: {
    type: Boolean,
  },
})

const { t } = useI18n()
const emit = defineEmits(['success', 'error'])

const {
  fetchPostClubContactMessage,
  watchPostClubContactMessage,
} = useApiMessagesStoreRequest()

const { phoneCode } = usePhoneCode()

// ***
// FORM DATA
// ***

const formContent = reactive({
  senderName: '',
  senderPhone: {
    code: '',
    number: '',
  },
  senderEmail: '',
  message: '',
})

const isFormValid = computed(() => (
  formContent.senderName
    && formContent.senderPhone.number
    && formContent.message
))

const processedForm = computed(() => {
  const formCopy = {
    ...formContent,
    ...{ senderPhone: formContent.senderPhone.number },
    ...{ subject: props.subject },
  }
  return formCopy
})

// ***
// SET AVAILABLE USER INFO
// ***

onBeforeMount(() => {
  if (props.isExternal) return
  const { currentUser } = useGetLegacyPeopleGroupByClub()
  if (!currentUser.value) return
  const userInfo = currentUser.value

  Object.assign(formContent, {
    ...((userInfo.email) && { senderEmail: userInfo.email }),
    ...((userInfo.name && userInfo.lastname) && { senderName: `${userInfo.name} ${userInfo.lastname}` }),
    ...((userInfo.phone) && { senderPhone: { code: phoneCode.value, number: userInfo.phone } }),

  })
})

// ***
// SUBMIT DATA
// ***

function submitForm() {
  fetchPostClubContactMessage({
    params: { clubHash: props.clubHash },
    data: processedForm.value,
  })
}

watchPostClubContactMessage({
  onSuccess: () => {
    emit('success')
  },
  onError: () => {
    emit('error')
  },
})

</script>

<template>
  <FormKit
    type="form"
    :classes="{
      form: 'space-y-5 p-[2px]',
    }"
    :actions="false"
    @submit="submitForm"
  >
    <FormKit
      v-model="formContent.senderName"
      data-testId="senderName"
      type="text"
      :label="t('Name and surname')"
    />
    <CbPhone
      v-model="formContent.senderPhone"
      hide-prefix-until-backend-support
    />
    <FormKit
      v-model="formContent.senderEmail"
      data-testId="senderEmail"
      type="email"
      validation="email"
      :label="t('Email')"
    />
    <FormKit
      v-model="formContent.message"
      data-testId="contactMessage"
      :classes="{
        input: 'min-h-[10rem] p-1',
      }"
      type="textarea"
      :label="t('Reason for your enquiry')"
    />
    <div class="flex space-x-3 mb-3 justify-end">
      <slot name="options" />
      <CbButton
        data-testId="formSubmit"
        type="submit"
        mode="filled"
        color="primary"
        :disabled="!isFormValid"
      >
        {{ t('Send') }}
      </CbButton>
    </div>
  </FormKit>
</template>
