import { useApiStore } from '@core/store/apiRequest/apiRequest'

import { type ApiMessagesStoreShape } from '../declarations'

import { useMailboxesModule } from './mailboxes'
import { useThreadsModule } from './threads'

export const useApiMessagesStore = useApiStore<'apiMessages', ApiMessagesStoreShape>('apiMessages')

// ***
// REQUESTS
// ***

export function useApiMessagesStoreRequest() {
  const store = useApiMessagesStore()

  return {
    store,
    ...useMailboxesModule(store),
    ...useThreadsModule(store),
  }
}
