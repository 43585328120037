import {
  type ApiHydraResponse,
  type ApiStore,
} from '@core/store/apiRequest/declarations'
import { ClubResourceWithRoles } from '@dashboard/modules/ProfileSelector/declarations'

// ***
// COMMON
// ***

export interface UserProfile {
  clubs: ClubResourceWithRoles[]
  user: {
    '@id': '/api/users/722612102',
    '@type': 'User'
  }
  username: string,
  admin: boolean,
  error?: {
    code: number,
    message: string,
  }
}

// ***
// REQUESTS
// ***

export interface PatchUserChangePasswordRequest {
  data: {
    currentPassword: string,
    newPassword: string,
  },
}

export interface PostUserPasswordResetRequest {
  data: {
    username: string,
    method: string
  },
}
export interface PostUserPasswordResetValidateRequest {
  data: {
    username: string,
    code: string,
  },
}

export interface PostUserPasswordResetConfirmRequest {
  data: {
    username: string,
    code: string,
    newPassword: string,
    confirmNewPassword: string,
  },
}

// ***
// SHAPES
// ***

export interface ApiUserChangePassword extends ApiHydraResponse {
  password: string,
}

export interface UserStore {
  getUserProfile?: ApiStore<ApiHydraResponse<UserProfile>>,
  patchUserChangePassword: ApiUserChangePassword,
}

// ***
// MESSAGES
// ***

export enum PatchUserChangePasswordErrorMessages {
  BadCurrentPassword = 'Password is wrong',
}
