<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { CbSelect } from '@cluber/carabiner'

// ***
// DEFAULTS
// ***
const { locale, availableLocales } = useI18n()

const availableLocaleLabels = {
  'en-US': 'English(US)',
  'es-ES': 'Español(ES)',
  'gl-ES': 'Galego',
  'eu-ES': 'Euskara',
  'ca-ES': 'Català',
}

// ***
// LANGUAGE
// ***

</script>

<template>
  <div class="w-48">
    <h2 class="mb-5">
      Language preview
    </h2>
    <CbSelect
      id="LanguageSelector-devtools"
      v-model="locale"
      name="language_selector"
      class="block!"
      :options="availableLocales.map(item => ({
        value: item,
        label: `${availableLocaleLabels[item as keyof typeof availableLocaleLabels]} (${item})`,
      }))"
    />
    <hr class="mt-5">
  </div>
</template>
