import { type UserProfile } from '@core/modules/User/declarations'

export const profileMock: UserProfile = {
  clubs: [
    {
      '@id': '/api/.well-known/genid/58828b64ea4f33aac0bf',
      '@type': 'ProfileClubsResponse',
      club: '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
      name: 'Club Deportivo Arsenal',
      roles: [
        'ROLE_MANAGER',
        'ROLE_GUARDIAN',
        'ROLE_MEMBER',
        'ROLE_EXTERNAL',
      ],
      locale: 'en-US',
    },
    {
      '@id': '/api/.well-known/genid/d4a4e94ab04f1c581f2d',
      '@type': 'ProfileClubsResponse',
      club: '/api/clubs/f3594450c5b729898f1b0de6792a96215cb8ace5',
      name: 'Lugo Sala',
      roles: [
        'ROLE_MANAGER',
      ],
      locale: 'en-US',
    },
  ],
  user: {
    '@id': '/api/users/722612102',
    '@type': 'User',
  },
  username: '722612102',
  admin: false,
}

export const profileWithAdminMock: UserProfile = {
  ...profileMock,
  admin: true,
}
