import { onScopeDispose } from 'vue'
import { type PiniaCustomProperties } from 'pinia'

import { ApiErrorStandardRFC } from '@core/store/apiRequest/declarations'

import { StoreSendRequestError } from '@services/errorHandling'
import { createRateLimitedRequest, type RequestOptions } from '@services/utils'

import {
  type Mailbox,
  type GetMailboxRequest,
  type GetMailboxesRequest,
  type DeleteMailboxRequest,
  type GetMailboxProfilesRequest,
  type PostMailboxRequest,
  type PatchMailboxRequest,
  type PostMailboxProfilesRequest,
  type PostDuplicateMailboxRequest,
  type DeleteMailboxProfileRequest,
} from '@services/Communications/declarations'

import {
  deleteMailbox,
  deleteMailboxProfile,
  getMailbox,
  getMailboxes,
  getMailboxProfiles,
  patchMailbox,
  postDuplicateMailbox,
  postMailbox,
  postMailboxProfiles,
} from '@services/Communications/api'

// ***
// COMMON
// ***

const serviceName = 'Communications'

// ***
// ERROR INFORMATION
// ***

const missingClubHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'clubHash param is required but not found',
  caller,
})

const missingMailboxHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'mailboxHash param is required but not found',
  caller,
})

const missingProfileHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'profileHash param is required but not found',
  caller,
})

// ***
// REQUESTS
// ***
export function useMailboxesModule<S extends PiniaCustomProperties>(store: S) {
  const { sendRequest, watchRequest } = store

  async function fetchGetMailboxes(
    requestConfig: GetMailboxesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetMailboxes')

    return createRateLimitedRequest<GetMailboxesRequest, Mailbox[]>({
      sendRequest,
      watchRequest,
      endpoint: getMailboxes,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetMailboxes = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Mailbox[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Mailbox[]>(getMailboxes, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetMailbox(
    requestConfig: GetMailboxRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetMailbox')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('fetchGetMailbox')

    return createRateLimitedRequest<GetMailboxRequest, Mailbox>({
      sendRequest,
      watchRequest,
      endpoint: getMailbox,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetMailbox = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Mailbox) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Mailbox>(getMailbox, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Post mailbox

  async function fetchPostMailbox(
    requestConfig: PostMailboxRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPostMailbox')

    return createRateLimitedRequest<PostMailboxRequest, Mailbox>({
      sendRequest,
      watchRequest,
      endpoint: postMailbox,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPostMailbox = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Mailbox) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Mailbox>(postMailbox, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Patch mailbox
  async function fetchPatchMailbox(
    requestConfig: PatchMailboxRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPatchMailbox')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('fetchPatchMailbox')

    return createRateLimitedRequest<PatchMailboxRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: patchMailbox,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPatchMailbox = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Mailbox) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Mailbox>(patchMailbox, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Delete mailbox
  async function fetchDeleteMailbox(
    requestConfig: DeleteMailboxRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('deleteMailbox')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('deleteMailbox')

    return createRateLimitedRequest<DeleteMailboxRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: deleteMailbox,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchDeleteMailbox = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Mailbox) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Mailbox>(deleteMailbox, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Post duplicate mailbox
  async function fetchPostDuplicateMailbox(
    requestConfig: PostDuplicateMailboxRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('deleteMailbox')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('deleteMailbox')

    return createRateLimitedRequest<PostDuplicateMailboxRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: postDuplicateMailbox,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPostDuplicateMailbox = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Mailbox) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Mailbox>(postDuplicateMailbox, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Get mailbox profiles
  async function fetchGetMailboxProfiles(
    requestConfig: GetMailboxProfilesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetMailboxProfiles')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('fetchGetMailboxProfiles')

    return createRateLimitedRequest<GetMailboxProfilesRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: getMailboxProfiles,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetMailboxProfiles = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: unknown) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<unknown>(getMailboxProfiles, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Post mailbox profiles
  async function fetchPostMailboxProfiles(
    requestConfig: PostMailboxProfilesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPostMailboxProfiles')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('fetchPostMailboxProfiles')

    return createRateLimitedRequest<PostMailboxProfilesRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: postMailboxProfiles,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPostMailboxProfiles = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: unknown) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<unknown>(postMailboxProfiles, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // Delete mailbox profile
  async function fetchDeleteMailboxProfile(
    requestConfig: DeleteMailboxProfileRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchDeleteMailboxProfile')
    if (!requestConfig?.params?.mailboxHash) throw missingMailboxHashError('fetchDeleteMailboxProfile')
    if (!requestConfig?.params?.profileHash) throw missingProfileHashError('fetchDeleteMailboxProfile')

    return createRateLimitedRequest<PostMailboxProfilesRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: deleteMailboxProfile,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchDeleteMailboxProfile = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: unknown) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<unknown>(deleteMailboxProfile, {
      onSuccess: (data) => onSuccess(data),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  return {
    fetchGetMailbox,
    fetchPostMailbox,
    fetchPatchMailbox,
    fetchGetMailboxes,
    fetchDeleteMailbox,
    fetchPostDuplicateMailbox,
    fetchGetMailboxProfiles,
    fetchPostMailboxProfiles,
    fetchDeleteMailboxProfile,

    // watchers
    watchDeleteMailboxProfile,
    watchPostDuplicateMailbox,
    watchDeleteMailbox,
    watchGetMailbox,
    watchPostMailbox,
    watchPatchMailbox,
    watchGetMailboxes,
    watchGetMailboxProfiles,
    watchPostMailboxProfiles,
  }
}
