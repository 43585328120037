export const bookingData = {
  requestInternalState: { statusName: 'SUCCESS', isFetching: false },
  '@context': '/api/contexts/Booking',
  '@id': '/api/bookings/14',
  '@type': 'Booking',
  id: 14,
  hash: '1a37d7fba41aef3b1b2dbfb99b146a73245d6d2d',
  charge: {
    '@type': 'Charge', '@id': '/api/.well-known/genid/7f9fa81cf3b2e88631e5', id: 100918, personId: 6022, clubId: 52, amount: 1000, description: 'Cuota deportista: Mensual - 1 hijo - ago.', status: 'READY', resent: false, collectAt: '2024-11-11T00:00:00+00:00', createdAt: '2024-11-11T11:20:12+00:00', updatedAt: '2024-11-11T11:20:12+00:00', type: 'Guardian', feePayer: 'user', fee: 300, vat: 2100, providerReference: 'TPV',
  },
  provider: 'timetime',
  providerBookingId: '2fb1d0cb-a1da-4683-afaa-c5107aeab178',
  providerCalendarId: 'calendar-id',
  bookerEmail: 'alberto.lopez@siguetuliga.com',
  notificationEmails: 'alberto.lopez@siguetuliga.com',
  description: 'Pista de pádel 1h30',
  notes: 'asdfdas',
  startAt: '2024-11-28T15:00:00+00:00',
  endAt: '2024-11-28T16:30:00+00:00',
  amount: 500,
  currency: 'EUR',
  status: 'confirmed',
  createdAt: '2024-11-27T18:01:14+00:00',
  confirmedAt: '2024-11-27T18:09:48+00:00',
}

export const emptyBookingData = {
  requestInternalState: { statusName: 'SUCCESS', isFetching: false },
  '@context': '/api/contexts/Booking',
  '@id': '/api/bookings/14',
  '@type': 'Booking',
  id: 14,
  hash: '1a37d7fba41aef3b1b2dbfb99b146a73245d6d2d',
  charge: {
    '@type': 'Charge', '@id': '/api/.well-known/genid/7f9fa81cf3b2e88631e5', id: 100918, personId: 6022, clubId: 52, amount: 1000, description: 'Cuota deportista: Mensual - 1 hijo - ago.', status: 'READY', resent: false, collectAt: '2024-11-11T00:00:00+00:00', createdAt: '2024-11-11T11:20:12+00:00', updatedAt: '2024-11-11T11:20:12+00:00', type: 'Guardian', feePayer: 'user', fee: 300, vat: 2100, providerReference: 'TPV',
  },
  provider: 'timetime',
  providerBookingId: '2fb1d0cb-a1da-4683-afaa-c5107aeab178',
  bookerEmail: 'alberto.lopez@siguetuliga.com',
  notificationEmails: 'alberto.lopez@siguetuliga.com',
  description: 'Pista de pádel 1h30',
  notes: 'asdfdas',
  startAt: '2024-11-28T15:00:00+00:00',
  endAt: '2024-11-28T16:30:00+00:00',
  amount: 0,
  currency: 'EUR',
  status: 'confirmed',
  createdAt: '2024-11-27T18:01:14+00:00',
  confirmedAt: '2024-11-27T18:09:48+00:00',
}
