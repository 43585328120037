import {
  http,
  HttpResponse,
  type RequestHandler,
} from 'msw'

import { getApiRoute } from '@core/utils/api'

import {
  getClubByHash,
  getClubByShortName,
  getClubProfiles,
  getClubs,
  getClubEnabledFeatures,
} from '@services/Club/api'

import {
  getClubByHashMock,
  getClubProfilesMock,
  getClubsMock,
  getClubEnabledFeaturesMock,
} from '@services/Club/mocks'
import { groupByClubLegacy } from '@dashboard/modules/ProfileSelector/requestHandlers/mockData'

export const getClubByHashHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubByHash.url, { params: { clubHash: groupByClubLegacy.club.id } }),
  () => HttpResponse.json(getClubByHashMock),
)

export const getClubByShortNameHandler = http.get(
  getApiRoute<{ clubHash: string }>(
    getClubByShortName.url,
    { params: { clubShortName: groupByClubLegacy.club.shortname } },
  ),
  () => HttpResponse.json(getClubByHashMock),
)

export const getClubProfilesHandler: RequestHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubProfiles.url, { params: { clubHash: groupByClubLegacy.club.id } }),
  () => HttpResponse.json(getClubProfilesMock),
)

export const getClubsHandler: RequestHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubs.url, { params: { clubHash: groupByClubLegacy.club.id } }),
  () => HttpResponse.json(getClubsMock),
)

export const getClubEnabledFeaturesHandler: RequestHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubEnabledFeatures.url, { params: { clubHash: groupByClubLegacy.club.id } }),
  () => HttpResponse.json(getClubEnabledFeaturesMock),
)

export default [
  getClubByHashHandler,
  getClubProfilesHandler,
  getClubByShortNameHandler,
  getClubsHandler,
  getClubEnabledFeaturesHandler,
]
