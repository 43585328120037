/* eslint-disable no-console */
// import * as Sentry from '@sentry/vue'

/**
 * Class for handling store send request errors.
 * This class extends the base Error but is intended to be used as sentry error handler.
 *
 * @example
 * const error = new StoreSendRequestError('Error message', 'Club service: fetchClubs')
 * console.log(error.sendToLogPlatform()) // 'Sent to Sentry, Rocket Log, etc.'
 */

interface ErrorDetails {
  code?: number;
  statusCode?: number;
  status?: number;
  message?: string;
  [key: string]: unknown;
}

class StoreSendRequestError extends Error {
  private caller: string

  private error: string | ErrorDetails

  private service: string

  private componentInfo?: string

  constructor({
    message,
    caller,
    service,
    error,
  }: {
    message: string,
    caller: string,
    service: string,
    error?: string | ErrorDetails
  }) {
    super(message)

    this.name = 'StoreSendRequestError'
    this.caller = caller
    this.error = error || ''
    this.service = service
    this.componentInfo = this.extractComponentInfo()

    // Capture stack trace with proper caller information
    Error.captureStackTrace(this, StoreSendRequestError)
    this.printError()
  }

  private extractComponentInfo(): string {
    if (!this.stack) return 'Unknown location'

    // Get the first line after the error message that isn't from this file
    const stackLines = this.stack.split('\n')
    const callerLine = stackLines.find(line => (
      line.includes('.vue:')
      || (line.includes('/components/') && !line.includes('errorHandling.ts'))
    ))

    // Extract the file name from the path
    const fileName = callerLine ? callerLine.substring(callerLine.lastIndexOf('(') - 1) : ''
    return fileName.replace(/[()]/g, '').trim()
  }

  printError(): void {
    console.error(`${this.name}: ${this.message}`, {
      service: this.service,
      message: this.message,
      caller: this.caller,
      location: this.componentInfo,
      ...(this.error && { error: this.error }),
    })
  }

  getCaller(): string {
    return this.caller
  }

  getError(): string | ErrorDetails {
    return this.error
  }

  getCodeStatus(): number | undefined {
    if (typeof this.error === 'object') {
      const errorObj: ErrorDetails = this.error
      return errorObj.code
        || errorObj.statusCode
        || errorObj.status
    }
    return undefined
  }

  getComponentInfo(): string {
    return this.componentInfo || 'Unknown location'
  }

  // sendToLogPlatform(): void {
  //   this.printError()
  //   // TODO: Implement error logging platform integration
  //   // Example:
  //   // errorLogger.captureException(this, {
  //   //   tags: {
  //   //     caller: this.getCaller(),
  //   //     message: this.message,
  //   //     component: this.getComponentInfo(),
  //   //   },
  //   //   extra: {
  //   //     error: this.error,
  //   //   }
  //   // })
  // }
}

export {
  StoreSendRequestError,
}
