import {
  StoreSendRequestError,
} from '@services/errorHandling'

import { onScopeDispose } from 'vue'

import { ApiErrorStandardRFC } from '@core/store/apiRequest/declarations'

import {
  type ClubStoreShape,
  type ClubInfoExtended,
  type GetClubEnabledFeaturesRequest,
  type GetClubByHashRequest,
  type GetClubByShortNameRequest,
  type GetClubProfilesRequest,
  type GetClubsRequest,
  type GetClubPaymentsMethodsOnboardLinksRequest,
  type GetClubPaymentsOnboardRequest,
  type ClubEnabledFeatures,
  type ClubUserProfile,
  type ClubInfo,
  type ClubPaymentsMethodsOnboardLinks,
  type ClubPaymentsOnboard,
} from '@services/Club/declarations'

import {
  getClubEnabledFeatures,
  getClubByHash,
  getClubByShortName,
  getClubProfiles,
  getClubs,
  getClubPaymentsMethodsOnboardLinks,
  getClubPaymentsOnboard,
} from '@services/Club/api'

import { useApiStore } from '@core/store/apiRequest/apiRequest'
import { createRateLimitedRequest, type RequestOptions } from '@services/utils'

// ***
// COMMON
// ***
export const useApiClubStore = useApiStore<'apiClub', ClubStoreShape>('apiClub')

const serviceName = 'Club'

const missingClubHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'clubHash param is required but not found',
  caller,
})

// ***
// REQUESTS
// ***

export function useApiClubStoreRequest() {
  const store = useApiClubStore()
  const { sendRequest, watchRequest } = store

  // Now each request becomes much simpler
  async function fetchGetClubEnabledFeatures(
    requestConfig: GetClubEnabledFeaturesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubEnabledFeatures')

    return createRateLimitedRequest<GetClubEnabledFeaturesRequest, ClubEnabledFeatures>({
      sendRequest,
      watchRequest,
      endpoint: getClubEnabledFeatures,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubEnabledFeatures = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ClubEnabledFeatures, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ClubEnabledFeatures>(getClubEnabledFeatures, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // FETCH GET CLUB BY HASH
  async function fetchGetClubByHash(
    requestConfig: GetClubByHashRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubByHash')

    return createRateLimitedRequest<GetClubByHashRequest, ClubInfoExtended>({
      sendRequest,
      watchRequest,
      endpoint: getClubByHash,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubByHash = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ClubInfoExtended, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ClubInfoExtended>(getClubByHash, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // FETCH GET CLUB BY SHORT NAME
  async function fetchGetClubByShortName(
    requestConfig: GetClubByShortNameRequest,
    options?: RequestOptions,
  ) {
    const error = () => new StoreSendRequestError({
      service: serviceName,
      message: 'clubShortName param is required but not found',
      caller: 'fetchGetClubByShortName',
    })

    if (!requestConfig?.params?.clubShortName) throw error()

    return createRateLimitedRequest<GetClubByShortNameRequest, ClubInfoExtended>({
      sendRequest,
      watchRequest,
      endpoint: getClubByShortName,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubByShortName = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: unknown, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<unknown>(getClubByShortName, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // FETCH GET CLUB PROFILES
  async function fetchGetClubProfiles(
    requestConfig: GetClubProfilesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubProfiles')

    return createRateLimitedRequest<GetClubProfilesRequest, ClubUserProfile[]>({
      sendRequest,
      watchRequest,
      endpoint: getClubProfiles,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubProfiles = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ClubUserProfile[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ClubUserProfile[]>(getClubProfiles, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // FETCH GET CLUBS
  async function fetchGetClubs(requestConfig: GetClubsRequest, options?: RequestOptions) {
    return createRateLimitedRequest<GetClubsRequest, ClubInfo[]>({
      sendRequest,
      watchRequest,
      endpoint: getClubs,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubs = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ClubInfo[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ClubInfo[]>(getClubs, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // FETCH GET CLUB PAYMENTS METHODS ONBOARD LINKS
  async function fetchGetClubPaymentsMethodsOnboardLinks(
    requestConfig: GetClubPaymentsMethodsOnboardLinksRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubPaymentsMethodsOnboardLinks')

    return createRateLimitedRequest<GetClubPaymentsMethodsOnboardLinksRequest, ClubPaymentsMethodsOnboardLinks>({
      sendRequest,
      watchRequest,
      endpoint: getClubPaymentsMethodsOnboardLinks,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubPaymentsMethodsOnboardLinks = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ClubPaymentsMethodsOnboardLinks, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ClubPaymentsMethodsOnboardLinks>(getClubPaymentsMethodsOnboardLinks, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  // FETCH GET CLUB PAYMENTS ONBOARD
  async function fetchGetClubPaymentsOnboard(
    requestConfig: GetClubPaymentsOnboardRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetClubPaymentsOnboard')

    return createRateLimitedRequest<GetClubPaymentsOnboardRequest, ClubPaymentsOnboard>({
      sendRequest,
      watchRequest,
      endpoint: getClubPaymentsOnboard,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetClubPaymentsOnboard = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ClubPaymentsOnboard, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ClubPaymentsOnboard>(getClubPaymentsOnboard, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  return {
    store,
    fetchGetClubEnabledFeatures,
    fetchGetClubByHash,
    fetchGetClubByShortName,
    fetchGetClubProfiles,
    fetchGetClubs,
    fetchGetClubPaymentsMethodsOnboardLinks,
    fetchGetClubPaymentsOnboard,

    // watchers
    watchGetClubEnabledFeatures,
    watchGetClubByHash,
    watchGetClubByShortName,
    watchGetClubProfiles,
    watchGetClubs,
    watchGetClubPaymentsMethodsOnboardLinks,
  }
}
