import {
  type ApiHydraResponse,
  type ApiHydraListResponse,
  type ApiRequestQueryTypeKinds,
} from '@services/declarations'

import { type ClubResourceWithRoles } from '@services/Legacy/declarations'

// ***
// COMMON
// ***

export enum RoleKind {
  Member = 'ROLE_MEMBER',
  Guardian = 'ROLE_GUARDIAN',
  Manager = 'ROLE_MANAGER',
  Player = 'ROLE_PLAYER',
  External = 'ROLE_EXTERNAL',
  Admin = 'ROLE_ADMIN',
}

export type AllowedRoleKind = `${RoleKind}`

export type ExcludeExternalRole = Exclude<AllowedRoleKind, 'ROLE_EXTERNAL'>

export enum UserKind {
  Member = 'member',
  Guardian = 'guardian',
  External = 'external',
  Manager = 'manager',
  Player = 'player',
  Admin = 'admin',
}

export type AllowedUserKind = `${UserKind}`

export type ExcludeExternalKind = Exclude<AllowedUserKind, 'external'>

// ***
// REQUESTS
// ***

// GET

// POST

// ***
// SHAPES
// ***
export interface UserProfile {
  clubs: ClubResourceWithRoles[]
  user: {
    '@id': '/api/users/722612102',
    '@type': 'User'
  }
  username: string,
  admin: boolean,
  error?: {
    code: number,
    message: string,
  }
}

// ***
// STORE
// ***

export interface UserStoreShape {
  getUserByUsername: unknown,
  getUserProfile: unknown,
  postUserPasswordResetConfirm: unknown,
  postUserPasswordResetRequest: unknown,
  postUserPasswordResetValidate: unknown,
}
