// ---> If you have any typescript issue, types are overwritten by PINIA so look for pinia.d.ts file <---
import { ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  defineStore,
  getActivePinia,
  MutationType,
  type StateTree,
  type Store,
} from 'pinia'
import { useDebounceFn } from '@vueuse/core'

import { type CustomRequestProperties } from '@core/store/pinia'
import { apiURL } from '@core/config'

import { omit } from 'lodash-es'

import axios, { type AxiosResponse, type AxiosError } from 'axios'

import { reactiveApiUrls, getApiRoute } from '@core/utils/api'

import {
  ApiRequestStatus,
  type ApiRequestStatusKinds,
  type EndpointConfig,
  type AllowedRequestConfigProperties,
  type ApiRequestStoreKey,
  type ApiStoreRequestStatus,
  type ApiMethods,
  type ApiStoreKey,
  type ApiCollectionStore,
  type ApiHydraCollectionResponse,
  type ApiStore,
  ApiErrorStandardRFC,
} from '@core/store/apiRequest/declarations'

import { MutationSubscription, ExtendedPinia } from '@core/store/pinia'

// ***
// COMMON
// ***
export const axiosInstance = axios.create()

// Error checker to avoid issues between strings and object
function setError(error?: AxiosError): AxiosError | object | null {
  if (typeof error === 'string') {
    return {
      error: {
        message: error,
      },
    }
  }

  return typeof error === 'object' ? error : null
}

function setHeaders(requestMethod: ApiMethods, customHeaders: AllowedRequestConfigProperties['headers']) {
  // Patch requests needs special content-type header
  const patchHeader = { 'Content-Type': 'application/merge-patch+json' }

  if (requestMethod === 'PATCH') {
    if (!customHeaders || !Object.keys(customHeaders).length) {
      return { headers: patchHeader }
    }

    Object.assign(customHeaders, patchHeader)
  }

  return { headers: customHeaders }
}

// ***
// TOKEN CHECK
// ***
export function isJWTExpired(token: string) {
  if (!token) return true
  let expiry
  try {
    expiry = (JSON.parse(atob(token.split('.')[1]))).exp as number
  } catch (_) {
    return true
  }
  return (Math.floor((new Date()).getTime() / 1000)) >= expiry
}

// ***
// STORE GENERATOR
// ***

const collectionCounter: Ref<Promise<AxiosResponse<unknown, unknown>>[]> = ref([])
const debouncedCollectionRequest = useDebounceFn((callback) => { callback() }, 1000)

export function useApiStore<
  StoreId extends ApiStoreKey,
  State extends StateTree,
>(storeName: StoreId): ReturnType<typeof defineStore<StoreId, State>> {
  // @ts-expect-error looking for solution
  return defineStore(storeName, () => {
    const store = ref<Store<StoreId, State>>()

    function getCurrentStore() {
      // eslint-disable-next-line no-underscore-dangle
      (getActivePinia() as ExtendedPinia<StoreId, State>)?._s.forEach(singleStore => {
        if (!store.value && (singleStore.$id === storeName)) {
          store.value = singleStore
        }
      })
    }

    /**
     * Patches current store with data retrieved from the last request
     * sets response or error depending on the endpoint response
     * @param storeStateId - string with the name of the property to save on storage I.E 'getClubList'
     * @param store - current defined store'
     * @param destructured `{ request, response, error }` - object containing needed data from endpoint response
     * @param isCollection - use it if you want to group X amount of items under the same store key
     */

    // ***** README README ***** This functions types are replaced by ones set on pinia.d.ts change there
    function patchStore<ResponseShape = unknown>(
      storeStateId: Ref<ApiRequestStoreKey>,
      { request, response, error }: {
        request?: ApiStoreRequestStatus,
        response?: AxiosResponse<ResponseShape> | AxiosResponse<ResponseShape>[],
        error?: AxiosError
      },
    ) {
      const parsedResponse = () => {
        if (response && !Array.isArray(response) && response.data) {
          return response.data
        }
        if (response && Array.isArray(response)) {
          return { collection: response.map(item => item.data) }
        }
        return response
      }

      if (store.value && request?.statusName === ApiRequestStatus.Request) {
        // store.value.$patch({
        //   [storeStateId.value]: {
        //    requestInternalState: request,
        //  },
        // })
      }

      if (store.value && response) {
        // @ts-expect-error Currently I don't know a proper solution
        store.value.$patch({
          [storeStateId.value]: {
            requestInternalState: request,
            ...(response && { ...parsedResponse() }),
          },
        })
      }

      const filteredError = setError(error)
      if (store.value && !response && filteredError) {
        // @ts-expect-error Currently I don't know a proper solution
        store.value.$patch({
          [storeStateId.value]: {
            requestInternalState: request,
            ...(filteredError && {
              error: (filteredError as AxiosError)?.response?.data,
            }),
          },
        })
      }
    }

    /**
     * Makes a request to the desired endpoint. Data, query, params and custom request configs
     * should be set if needed.
     * @param endpointConfig - Object containgin storeKey (how will we named in the store) and url for request
     * @param requestConfig - Object containing, query, params, data, config
     * @param endpointName - The endpoint name cames from `@core/config/index.ts --> apiURL` and sets that url
     * @param dataPath - this is only needed if the response path differs from `response.data.data` I.E `response.data.gears`
     */

    // ***** README README ***** This functions types are replaced by ones set on pinia.d.ts change there
    function sendRequest<RequestConfig>(
      {
        endpointConfig,
        requestConfig,
        endpointName = 'main',
        isCollection = false,
      }: {
        endpointConfig: EndpointConfig,
        requestConfig?: AllowedRequestConfigProperties<RequestConfig>,
        endpointName?: keyof typeof apiURL,
        isCollection: boolean,
      },
    ) {
      // Get current store when request takes place
      getCurrentStore()

      const currentMethod = ref<ApiMethods>('GET')
      const storeStateId: Ref<ApiRequestStoreKey> = ref(endpointConfig.storeKey)
      const getMethod = endpointConfig.storeKey.split(/(?=[A-Z])/)[0]
      currentMethod.value = getMethod.toUpperCase() as ApiMethods
      const router = useRouter()

      // Set REQUEST status until something happens
      patchStore(storeStateId, {
        request: {
          statusName: ApiRequestStatus.Request,
          isFetching: true,
        },
      })

      const finalUrl = (requestConfig?.params || requestConfig?.query)
        ? getApiRoute(endpointConfig.url, requestConfig, endpointName)
        : `${reactiveApiUrls[endpointName]}${endpointConfig.url}`

      if (isCollection) {
        collectionCounter.value.push(
          axiosInstance.request({
            method: currentMethod.value,
            url: finalUrl,
            data: requestConfig?.data,
            ...setHeaders(currentMethod.value, requestConfig?.headers as undefined),
          }),
        )
        debouncedCollectionRequest(() => {
          Promise.all(collectionCounter.value as Promise<AxiosResponse<unknown, RequestConfig>>[])
            .then((responses) => {
              patchStore<unknown>(storeStateId, {
                request: {
                  statusName: ApiRequestStatus.Success,
                  isFetching: false,
                },
                response: responses,
              })
            })
        })
      } else {
        axiosInstance.request({
          method: currentMethod.value,
          url: finalUrl,
          data: requestConfig?.data,
          ...setHeaders(currentMethod.value, requestConfig?.headers as undefined),
        }).then(response => {
          patchStore(storeStateId, {
            request: {
              statusName: ApiRequestStatus.Success,
              isFetching: false,
            },
            response,
          })
        }).catch((error: AxiosError) => {
          patchStore(storeStateId, {
            request: {
              statusName: ApiRequestStatus.Error,
              isFetching: false,
            },
            error,
          })
          if (error.response?.status === 401) {
            // Do not redirect to login if user is recovering password
            if (window.location.pathname?.includes('/password/recover')) return

            // Don't redirect if user is trying to login
            if (!axiosInstance.defaults.headers.common.Authorization) {
              if (window.location.pathname === '/auth/login') return
            }

            if (router) {
              const stringifiedRoute = JSON.stringify(router.currentRoute?.value?.fullPath)
              const parsedRoute = stringifiedRoute ? JSON.parse(stringifiedRoute) as string : ''
              const notAllowedRoutes = ['login', 'logout', 'profile-selector'].some(item => parsedRoute.includes(item))
              const urlQueryParams = new URLSearchParams(parsedRoute.split('?')[1])
              const isROOT = (urlQueryParams.get('from') === '/') || (parsedRoute === '/')

              router.push({
                name: 'authLogout',
                query: {
                  expiredToken: 'true',
                  ...((!isROOT || !notAllowedRoutes) && {
                    from: urlQueryParams.get('from') || parsedRoute,
                    keepCurrentUrl: 'true',
                  }),
                },
              })
            }
            // else {
            //   const logoutPath = authRoutes.routes.find(route => route.name === 'authLogout')?.path
            //   window.location.href = `${authRoutes.basePath}${logoutPath}?expiredToken=true`
            // }
          }
        })
      }
    }

    /**
     * Gets the current status of the request from store
     * @param storeKey - name of the store to look for data
     * @param mutation - current mutation to compare
     */

    // ***** README README ***** This functions types are replaced by ones set on pinia.d.ts change there
    function checkRequestStatus(
      storeKey: ApiRequestStoreKey,
      mutation: MutationSubscription,
    ): ApiRequestStatusKinds {
      // eslint-disable-next-line vue/max-len
      const status = (store.value?.$state[storeKey] as CustomRequestProperties)?.requestInternalState?.statusName as ApiRequestStatus
      if (mutation.type !== MutationType.patchObject) return 'IDLE'

      const mutationKey = Object.keys(mutation.payload)[0]
      return mutationKey === storeKey ? status : 'IDLE'
    }

    /**
     * Resets the current status of the request from store
     * @param storeKey - name of the store to look for data
     * @param kind - accepted status to reset 'CLEAR' or 'IDLE'
     */
    function resetRequestStatus(storeKey: ApiRequestStoreKey, kind: Exclude<ApiRequestStatusKinds, 'REQUEST' | 'SUCCESS' | 'ERROR'>) {
      (store.value?.$state[storeKey] as ApiStore<{ [k: string]: unknown }>).requestInternalState.statusName = kind
    }

    /**
    * Parses the data retrieved from endpoint to be agnostic from endpoint architecture
    * @param payload - Payload retrieved from api response
    */

    // ***** README README ***** This functions types are replaced by ones set on pinia.d.ts change there
    function convertApiDataFromStore<R>(storeKey: ApiRequestStoreKey): { data: R | undefined, totalItems: number } {
      const storeItemByKey = omit(store.value?.$state[storeKey] as object, ['request', 'requestInternalState'])
      const storeItemCollection = (storeItemByKey as ApiCollectionStore<R>)?.collection as R
      if (storeItemCollection && Array.isArray(storeItemCollection)) {
        return {
          data: storeItemCollection,
          totalItems: storeItemCollection.length,
        }
      }

      const storeItem = storeItemByKey as ApiHydraCollectionResponse<R>
      if (storeItem && storeItem['hydra:member']) {
        return {
          data: storeItem['hydra:member'] as R,
          totalItems: storeItem['hydra:totalItems'],
        }
      }

      if (storeItem && !storeItem['hydra:member']) {
        return {
          data: storeItem as R,
          totalItems: 0,
        }
      }

      return {
        data: undefined,
        totalItems: 0,
      }
    }

    /**
    * Wraps the original pinia $subscribe function to match the REQUEST, SUCCESS, ERROR flow of our requests
    * @param endpointConfig - Object containgin storeKey (how will we named in the store) and url for request
    * @param {Object} options - The options for handling different request states.
    * @param {() => void} [options.onRequest] - Callback invoked before the request is sent.
    * @param {(data?: Success) => void} [options.onSuccess] - Callback invoked when the request succeeds.
    * @param {(error?: Error) => void} [options.onError] - Callback invoked when the request fails.
    */

    function watchRequest<SuccessData, Error = ApiErrorStandardRFC>(
      endpointConfig: EndpointConfig,
      {
        onRequest = undefined,
        onSuccess = undefined,
        onError = undefined,
      } : {
        onRequest?: () => void,
        onSuccess?: (data?: SuccessData, totalItems?: number) => void,
        onError?: (error?: Error) => void,
      },
    ) {
      getCurrentStore()

      const closeSubscription = store.value?.$subscribe((mutation) => {
        if (mutation.type === MutationType.patchObject) {
          const clubRequestStatus = checkRequestStatus(endpointConfig.storeKey, mutation)

          if (clubRequestStatus === ApiRequestStatus.Request) {
            onRequest?.()
          }
          if (clubRequestStatus === ApiRequestStatus.Success) {
            const { data, totalItems } = convertApiDataFromStore<SuccessData>(endpointConfig.storeKey)
            onSuccess?.(data, totalItems)
          }

          if (clubRequestStatus === ApiRequestStatus.Error) {
            const { data: error } = convertApiDataFromStore<Error>(endpointConfig.storeKey)
            onError?.(error)
          }
        }
      })

      return {
        closeSubscription,
      }
    }

    return {
      sendRequest,
      checkRequestStatus,
      convertApiDataFromStore,
      resetRequestStatus,
      watchRequest,
    }
  })
}
