import { onScopeDispose } from 'vue'
import { type PiniaCustomProperties } from 'pinia'

import { StoreSendRequestError } from '@services/errorHandling'
import { createRateLimitedRequest, type RequestOptions } from '@services/utils'
import { ApiErrorStandardRFC } from '@core/store/apiRequest/declarations'

import {
  deleteMessageThread,
  getAccesibleParticipants,
  getAllowedRecipients,
  getMessageThreads,
  getThreadBroadcastMessages,
  getThreadConversations,
  getThreadDetails,
  getThreadParticipants,
  getThreadSingleConversation,
  getUnreadMessages,
  patchRestoreThread,
  postClubContactMessage,
  postCreateMessageInThread,
  postCreateThread,
} from '@services/Communications/api'

import {
  type Conversation,
  type DeleteThreadRequest,
  type GetAccesibleParticipantsRequest,
  type GetAllowedRecipientsRequest,
  type GetThreadBroadcastMessagesRequest,
  type GetThreadConversationsRequest,
  type GetThreadDetailsRequest,
  type GetThreadParticipantsRequest,
  type GetThreadSingleConversationRequest,
  type PatchRestoreThreadRequest,
  type PostClubContactMessageRequest,
  type PostCreateMessageInThreadRequest,
  type PostCreateThreadRequest,
  type Recipient,
  type Thread,
  type ThreadBroadcastMessage,
  type ThreadData,
  type ThreadParticipant,
  type ThreadSingleConversation,
  type GetMessageThreadsRequest,
  type GetUnreadMessagesRequest,
  type UnreadMessages,

} from '../declarations'
// ***
// COMMON
// ***

const serviceName = 'Communications'

// ***
// ERROR INFORMATION
// ***

const missingClubHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'clubHash param is required but not found',
  caller,
})

const missingThreadHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'threadHash param is required but not found',
  caller,
})

const missingConversationHashError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'conversationHash param is required but not found',
  caller,
})

const missingProfileHeaderError = (caller: string) => new StoreSendRequestError({
  service: serviceName,
  message: 'Profile header is required but not found',
  caller,
})

// ***
// REQUESTS
// ***
export function useThreadsModule<S extends PiniaCustomProperties>(store: S) {
  const { sendRequest, watchRequest } = store

  async function fetchGetMessageThreads(
    requestConfig: GetMessageThreadsRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetMessageThreads')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetMessageThreads')

    return createRateLimitedRequest<GetMessageThreadsRequest, ThreadData[]>({
      sendRequest,
      watchRequest,
      endpoint: getMessageThreads,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetMessageThreads = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadData[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadData[]>(getMessageThreads, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchDeleteMessageThread(
    requestConfig: DeleteThreadRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchDeleteMessageThread')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchDeleteMessageThread')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchDeleteMessageThread')

    return createRateLimitedRequest<DeleteThreadRequest, ThreadData[]>({
      sendRequest,
      watchRequest,
      endpoint: deleteMessageThread,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchDeleteMessageThread = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadData[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadData[]>(deleteMessageThread, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchPostCreateThread(
    requestConfig: PostCreateThreadRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPostCreateThread')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchPostCreateThread')

    return createRateLimitedRequest<PostCreateThreadRequest, ThreadData[]>({
      sendRequest,
      watchRequest,
      endpoint: postCreateThread,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPostCreateThread = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadData[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadData[]>(postCreateThread, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetThreadDetails(
    requestConfig: GetThreadDetailsRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetThreadDetails')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchGetThreadDetails')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetThreadDetails')

    return createRateLimitedRequest<GetThreadDetailsRequest, Thread>({
      sendRequest,
      watchRequest,
      endpoint: getThreadDetails,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetThreadDetails = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Thread, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Thread>(getThreadDetails, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetThreadConversations(
    requestConfig: GetThreadConversationsRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetThreadConversations')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchGetThreadConversations')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetThreadConversations')

    return createRateLimitedRequest<GetThreadConversationsRequest, Conversation[]>({
      sendRequest,
      watchRequest,
      endpoint: getThreadConversations,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetThreadConversations = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Conversation[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Conversation[]>(getThreadConversations, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetThreadSingleConversation(
    requestConfig: GetThreadSingleConversationRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetThreadSingleConversation')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchGetThreadSingleConversation')
    if (!requestConfig?.params?.conversationHash) throw missingConversationHashError('fetchGetThreadSingleConversation')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetThreadSingleConversation')

    return createRateLimitedRequest<GetThreadSingleConversationRequest, ThreadSingleConversation[]>({
      sendRequest,
      watchRequest,
      endpoint: getThreadSingleConversation,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetThreadSingleConversation = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadSingleConversation[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadSingleConversation[]>(getThreadSingleConversation, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetThreadParticipants(
    requestConfig: GetThreadParticipantsRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetThreadParticipants')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchGetThreadParticipants')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetThreadParticipants')

    return createRateLimitedRequest<GetThreadParticipantsRequest, ThreadParticipant[]>({
      sendRequest,
      watchRequest,
      endpoint: getThreadParticipants,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetThreadParticipants = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadParticipant[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadParticipant[]>(getThreadParticipants, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchPatchRestoreThread(
    requestConfig: PatchRestoreThreadRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPatchRestoreThread')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchPatchRestoreThread')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchPatchRestoreThread')

    return createRateLimitedRequest<PatchRestoreThreadRequest, unknown>({
      sendRequest,
      watchRequest,
      endpoint: patchRestoreThread,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPatchRestoreThread = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: unknown, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<unknown>(patchRestoreThread, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetAccessibleParticipants(
    requestConfig: GetAccesibleParticipantsRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetAccessibleParticipants')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetAccessibleParticipants')

    return createRateLimitedRequest<GetAccesibleParticipantsRequest, Recipient[]>({
      sendRequest,
      watchRequest,
      endpoint: getAccesibleParticipants,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetAccessibleParticipants = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Recipient[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Recipient[]>(getAccesibleParticipants, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetAllowedRecipients(
    requestConfig: GetAllowedRecipientsRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetAllowedRecipients')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetAllowedRecipients')

    return createRateLimitedRequest<GetAllowedRecipientsRequest, Recipient[]>({
      sendRequest,
      watchRequest,
      endpoint: getAllowedRecipients,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetAllowedRecipients = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: Recipient[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<Recipient[]>(getAllowedRecipients, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetThreadBroadcastMessages(
    requestConfig: GetThreadBroadcastMessagesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetThreadBroadcastMessages')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchGetThreadBroadcastMessages')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetThreadBroadcastMessages')

    return createRateLimitedRequest<GetThreadBroadcastMessagesRequest, ThreadBroadcastMessage[]>({
      sendRequest,
      watchRequest,
      endpoint: getThreadBroadcastMessages,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetThreadBroadcastMessages = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadBroadcastMessage[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadBroadcastMessage[]>(getThreadBroadcastMessages, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchPostCreateMessageInThread(
    requestConfig: PostCreateMessageInThreadRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPostCreateMessageInThread')
    if (!requestConfig?.params?.threadHash) throw missingThreadHashError('fetchPostCreateMessageInThread')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchPostCreateMessageInThread')

    return createRateLimitedRequest<PostCreateMessageInThreadRequest, ThreadBroadcastMessage[]>({
      sendRequest,
      watchRequest,
      endpoint: postCreateMessageInThread,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPostCreateMessageInThread = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadBroadcastMessage[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadBroadcastMessage[]>(postCreateMessageInThread, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchPostClubContactMessage(
    requestConfig: PostClubContactMessageRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchPostClubContactMessage')

    return createRateLimitedRequest<PostClubContactMessageRequest, ThreadBroadcastMessage[]>({
      sendRequest,
      watchRequest,
      endpoint: postClubContactMessage,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchPostClubContactMessage = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: ThreadBroadcastMessage[], totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<ThreadBroadcastMessage[]>(postClubContactMessage, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  async function fetchGetUnreadMessages(
    requestConfig: GetUnreadMessagesRequest,
    options?: RequestOptions,
  ) {
    if (!requestConfig?.params?.clubHash) throw missingClubHashError('fetchGetUnreadMessages')
    if (!requestConfig?.headers?.Profile) throw missingProfileHeaderError('fetchGetUnreadMessages')

    return createRateLimitedRequest<GetUnreadMessagesRequest, UnreadMessages>({
      sendRequest,
      watchRequest,
      endpoint: getUnreadMessages,
      requestConfig,
      serviceName,
      ...options,
    })
  }

  const watchGetUnreadMessages = ({
    onSuccess,
    onError,
  }: {
    onSuccess: (data?: UnreadMessages, totalItems?: number) => void,
    onError: (error?: ApiErrorStandardRFC) => void,
  }) => {
    const { closeSubscription } = watchRequest<UnreadMessages>(getUnreadMessages, {
      onSuccess: (data, totalItems) => onSuccess(data, totalItems),
      onError: (error) => onError(error),
    })

    onScopeDispose(closeSubscription)
  }

  return {
    fetchGetMessageThreads,
    fetchDeleteMessageThread,
    fetchPostCreateThread,
    fetchGetThreadDetails,
    fetchGetThreadConversations,
    fetchGetThreadSingleConversation,
    fetchGetThreadParticipants,
    fetchGetAccessibleParticipants,
    fetchGetAllowedRecipients,
    fetchPatchRestoreThread,
    fetchGetThreadBroadcastMessages,
    fetchPostCreateMessageInThread,
    fetchPostClubContactMessage,
    fetchGetUnreadMessages,

    // watchers
    watchPatchRestoreThread,
    watchGetMessageThreads,
    watchDeleteMessageThread,
    watchPostCreateThread,
    watchGetThreadDetails,
    watchGetThreadConversations,
    watchGetThreadSingleConversation,
    watchGetThreadParticipants,
    watchGetAccessibleParticipants,
    watchGetAllowedRecipients,
    watchGetThreadBroadcastMessages,
    watchPostCreateMessageInThread,
    watchPostClubContactMessage,
    watchGetUnreadMessages,
  }
}
