<script setup lang="ts">
import {
  ref,
  type PropType,
} from 'vue'
import { useRouter } from 'vue-router'
import { onClickOutside } from '@vueuse/core'
import { CbLogo } from '@cluber/carabiner'

// ***
// DEFAULTS
// ***
const menuToggleCheckbox = ref<HTMLInputElement>()
const router = useRouter()

const emit = defineEmits([
  'toggle-inert',
])

const props = defineProps({
  nav: {
    type: Object as PropType<HTMLElement>,
    default: undefined,
  },
  showModal: {
    type: Boolean,
  },
})

// ***
// RESPONSIVE
// ***
function forceCloseNavigation() {
  if (!menuToggleCheckbox.value) return
  const checkbox = menuToggleCheckbox.value
  const isBlocked = window.getComputedStyle(checkbox).getPropertyValue('user-select') === 'none'

  if (!isBlocked && checkbox?.checked) {
    checkbox.click()
    emit('toggle-inert')
  }
}

router.afterEach((to, from) => {
  if (from.name !== to.name) forceCloseNavigation()
})

onClickOutside(props.nav, event => {
  if (!menuToggleCheckbox.value?.checked) return

  const element = event.target as HTMLElement
  if (element && element.id !== 'menuHamburgerCheckbox' && !props.showModal) {
    forceCloseNavigation()
  }
})
</script>

<template>
  <div
    data-testid="navigationResponsiveMenu"
    class="fixed top-0 left-0 z-40 flex items-center w-full h-16 transition-all duration-300 bg-white shadow md:hidden dark:bg-base-1000"
  >
    <div
      id="menuHamburgerToggle"
    >
      <input
        id="menuHamburgerCheckbox"
        ref="menuToggleCheckbox"
        type="checkbox"
        class="inputClip md:select-none"
      />
      <label
        id="navMenuIcon"
        for="menuHamburgerCheckbox"
        class="cursor-pointer"
      >
        <span />
        <span />
        <span />
      </label>
    </div>
    <CbLogo class="absolute h-16 p-3 mx-auto -translate-x-1/2 left-2/4" />
  </div>
</template>

<style>
body:has(nav div #menuHamburgerCheckbox:checked) {
  overflow: hidden;
  height: 100vh;
}

nav:has(#menuHamburgerCheckbox:checked) {
  width: 13rem;
}

#menuHamburgerToggle {
  --transition-duration: .5s;
  --transition-timing: cubic-bezier(.77, .2, .5, 1);
  display: block;
  margin-left: var(--spacing-5);
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  transition: transform var(--transition-duration) var(--transition-timing);

  & label span {
    background: var(--color-secondary-200);
    display: block;
    width: 27px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 20px -4px;
    transition: transform var(--transition-duration) var(--transition-timing),
                background var(--transition-duration) var(--transition-timing),
                opacity .55s ease;

    &:first-child {
      transform-origin: 0% 0%;
    }

    &:nth-last-child(2) {
      transform-origin: 52% 145%;
    }
  }

  & input:checked ~ label span {
    background: var(--color-primary-900);
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
  }

  & input:checked ~ label span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(.2, .2);
  }

  & input:checked ~ label span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }
}
</style>
