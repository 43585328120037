import router from '@dashboard/router'
import { AppModule } from '@libs/fsc/models/AppModule'
import { Auth } from '@core/modules/Auth'
import { ProfileSelector } from '@dashboard/modules/ProfileSelector'
import { Manager } from '@dashboard/modules/Manager'
import { Guardian } from '@dashboard/modules/Guardian'
import { Member } from '@dashboard/modules/Member'
import { Ecommerce } from '@dashboard/modules/Ecommerce'
import { Messages } from '@dashboard/modules/Messages'
import { AccessControl } from '@dashboard/modules/AccessControl'
import { Admin } from '@dashboard/modules/Admin'
import { Bookings } from '@dashboard/modules/Bookings'
import { Teams } from '@dashboard/modules/Teams'

export const dashboardModulesToRegister: Array<AppModule> = [
  Auth,
  Manager,
  Guardian,
  Member,
  ProfileSelector,
  Ecommerce,
  Messages,
  AccessControl,
  Admin,
  Bookings,
  Teams,
]

export function loadAppModules() {
  dashboardModulesToRegister.forEach(module => module.load(router))
}
