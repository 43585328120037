import { type UserStore } from '@core/modules/User/declarations'

import { useApiStore } from '@core/store/apiRequest/apiRequest'

import {
  type PostUserPasswordResetRequest,
  type PostUserPasswordResetValidateRequest,
  type PostUserPasswordResetConfirmRequest,
} from './declarations'

import {
  postUserPasswordResetConfirmConfig,
  postUserPasswordResetRequestConfig,
  postUserPasswordResetValidateConfig,
} from './api'

export const useApiUserStore = useApiStore<'apiUser', UserStore>('apiUser')

// ***
// REQUESTS
// ***

export function useUserApiRequests() {
  const { sendRequest, watchRequest } = useApiUserStore()

  function postUserPasswordResetConfirm({ data } : { data: PostUserPasswordResetConfirmRequest['data'] }) {
    sendRequest<PostUserPasswordResetConfirmRequest>({
      endpointConfig: postUserPasswordResetConfirmConfig,
      requestConfig: {
        data,
        headers: {
          Authorization: null,
        },
      },
    })
  }

  function postUserPasswordResetRequest({ data } : { data: PostUserPasswordResetRequest['data'] }) {
    sendRequest<PostUserPasswordResetRequest>({
      endpointConfig: postUserPasswordResetRequestConfig,
      requestConfig: {
        data,
        headers: {
          Authorization: null,
        },
      },
    })
  }

  function postUserPasswordResetValidate({ data } : { data: PostUserPasswordResetValidateRequest['data'] }) {
    sendRequest<PostUserPasswordResetValidateRequest>({
      endpointConfig: postUserPasswordResetValidateConfig,
      requestConfig: {
        data,
        headers: {
          Authorization: null,
        },
      },
    })
  }

  return {
    postUserPasswordResetConfirm,
    postUserPasswordResetRequest,
    postUserPasswordResetValidate,
    watchRequest,
  }
}
