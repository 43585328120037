import { http, HttpHandler, HttpResponse } from 'msw'

import { getApiRoute } from '@core/utils/api'

import { deleteBooking, getBooking } from './api'
import { bookingData } from './mocks'

export const getBookingHandler: HttpHandler = http.get(
  getApiRoute<{ bookingHash: string }>(getBooking.url, { params: { bookingHash: bookingData.hash } }),
  () => HttpResponse.json(bookingData),
)

export const deleteBookingHandler: HttpHandler = http.delete(
  getApiRoute<{ bookingHash: string }>(deleteBooking.url, { params: { bookingHash: bookingData.hash } }),
  () => {},
)
