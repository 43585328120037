import { EndpointConfig } from '@services/declarations'

export const getClubs: EndpointConfig = {
  storeKey: 'getClubs',
  url: '/api/clubs',
}

export const getClubByShortName: EndpointConfig = {
  storeKey: 'getClubByShortName',
  url: '/api/clubs/p/:clubShortName',
}

export const getClubBusinessData: EndpointConfig = {
  storeKey: 'getClubBusinessData',
  url: '/api/clubs/:clubHash/business-data',
}

export const getClubEnabledFeatures: EndpointConfig = {
  storeKey: 'getClubEnabledFeatures',
  url: '/api/clubs/:clubHash/features',
}

export const getClubPaymentsMethodsOnboardLinks: EndpointConfig = {
  storeKey: 'getClubPaymentsMethodsOnboardLinks',
  url: '/api/clubs/:clubHash/payments/methods/onboard-links',
}

export const getClubPaymentsOnboard: EndpointConfig = {
  storeKey: 'getClubPaymentsOnboard',
  url: '/api/clubs/:clubHash/payments/onboard',
}

export const getClubProfiles: EndpointConfig = {
  storeKey: 'getClubProfiles',
  url: '/api/clubs/:clubHash/profiles',
}

export const getClubByHash: EndpointConfig = {
  storeKey: 'getClubByHash',
  url: '/api/clubs/:clubHash',
}

export const postClubProfileContactClub: EndpointConfig = {
  storeKey: 'postClubProfileContactClub',
  url: '/api/clubs/:clubHash/profile-contact-club',
}