// ***
// DEFAULTS
// ***

export type ApiMethods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export enum ApiRequestStatus {
  Request = 'REQUEST',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Clear = 'CLEAR',
  Idle = 'IDLE',
}

export type ApiRequestStatusKinds = `${ApiRequestStatus}`

// ***
// QUERIES
// ***

export enum ApiRequestQueryTypeKinds {
  Between = 'between',
  Equals = 'equals',
  Contains = 'contains',
  GreatherThan = 'gt',
  GreatherThanEqual = 'gte',
  LessThan = 'lt',
  LessThanEqual = 'lte',
}

export type AllowedQueryTypeKinds = `${ApiRequestQueryTypeKinds}`

export enum ApiRequestQueryOperatorKinds {
  Or = 'or',
  And = 'and',
}

export type AllowedQueryOperatorKinds = `${ApiRequestQueryOperatorKinds}`

// ***
// ENDPOINT
// ***
export interface EndpointConfig {
  /**
   * `storeKey` Which parent property name will have the data coming from the response
   *
   * I.E: `{ myFancyStoreKey: { id: 'myId', number: 2 } }`
   */
  storeKey: ApiRequestStoreKey,

  /**
   * `url` To which url will point the request
   */
  url: string,
}

type AllowedKeysRequestConfig = 'query' | 'params' | 'data' | 'custom' | 'headers'

// @ts-expect-error do not know a proper solution to this
type RequestConfigChecker<T, K> = T extends object ? T[K] : never

export type AllowedRequestConfigProperties<T = unknown> = {
  [K in AllowedKeysRequestConfig]?: RequestConfigChecker<T, K>
}

// ***
// HYDRA STORE TYPES
// ***

export type ApiHydraResponse<T = unknown> = {
  '@id': string,
  '@type': string,
  '@context'?: string,
} & T

export type ApiHydraCollectionResponse<R> = ApiHydraResponse & {
  'hydra:member': Array<ApiHydraResponse<R>> | [],
  'hydra:totalItems': number,
}

// ***
// STORE TYPES
// ***

export type ApiRequestStoreKey = `${Lowercase<ApiMethods>}${string}`

export type ApiStoreKey = `${Lowercase<'api'>}${string}`

export interface ApiStoreRequestStatus {
  isFetching: boolean,
  statusName: ApiRequestStatusKinds,
}

export type ApiStore<R> = {
  requestInternalState: ApiStoreRequestStatus,
} & R

export interface ApiCollectionStore<R = unknown> {
  collection: R extends ApiHydraCollectionResponse<R>
    ? ApiHydraCollectionResponse<R>[]
    : ApiHydraResponse<R>[],
  requestInternalState: ApiStoreRequestStatus,
}

// ***
// ERROR
// ***
export interface ApiErrorDefault {
  error: {
    code: number,
    message: string,
  }
}
export interface ApiErrorStandardRFC {
  title: string,
  detail: string,
  status: number,
  trace: Record<string, unknown>,
  type: string,
  invalidParams?: Array<{
    field: string,
    errors: Array<string>
  }>
}

export interface ErrorStoreResponse {
  requestInternalState: ApiStoreRequestStatus,
  error: ApiErrorStandardRFC,
}
