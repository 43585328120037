<script setup lang="ts">
import { type PropType } from 'vue'
import { useRouter } from 'vue-router'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import LegacyLink from '@dashboard/components/LegacyLink/LegacyLink.vue'

import { type NavItemRoute, type NavigationItem } from '../declarations'

import NavItemBadge from '../NavItemBadge/NavItemBadge.vue'

// ***
// DEFAULTS
// ***
const router = useRouter()

const {
  currentUser,
} = useGetLegacyPeopleGroupByClub()

const emit = defineEmits([
  'nav-item-clicked',
])

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  route: {
    type: Object as PropType<NavItemRoute>,
    default: undefined,
  },
  legacyRoute: {
    type: Function as PropType<NavigationItem['legacyRoute']>,
    default: undefined,
  },
  action: {
    type: Function as PropType<NavigationItem['action']>,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  badge: {
    type: String,
    default: undefined,
  },
})

// ***
// ACTIONS
// ***

function runAction() {
  if (props.action) props.action({ router, currentUser: currentUser.value })
  emit('nav-item-clicked', props.id)
}
</script>

<template>
  <li
    class="flex items-center w-full p-3 hover:bg-base-300 dark:hover:bg-base-500 cursor-pointer"
    tabindex="0"
    @click="runAction"
    @keydown.enter="runAction"
  >
    <RouterLink
      v-if="route"
      class="w-full"
      :to="route"
    >
      <span
        v-if="icon"
        :class="`bi ${icon} mr-3`"
        data-testid="navitem-prefix-icon"
      />
      <slot />
      <NavItemBadge
        :badge="badge"
      />
    </RouterLink>
    <LegacyLink
      v-else-if="legacyRoute"
      v-slot="{ navigate }"
      class="w-full"
      data-testid="legacyLink"
      :to="legacyRoute({ router, currentUser })"
    >
      <div
        @click="navigate"
        @keypress.enter="navigate"
      >
        <span
          v-if="icon"
          :class="`bi ${icon} mr-3`"
          data-testid="navitem-prefix-icon"
        />
        <slot />
        <NavItemBadge
          :badge="badge"
        />
      </div>
    </LegacyLink>
    <template v-else>
      <span
        v-if="icon"
        :class="`bi ${icon} mr-3`"
        data-testid="navitem-prefix-icon"
      />
      <slot />
      <NavItemBadge
        :badge="badge"
      />
    </template>
  </li>
</template>
