import {
  ref,
  nextTick,
  computed,
  type ComputedRef,
} from 'vue'
import { getActivePinia } from 'pinia'
import { useAppConfigStore } from '@core/store/app'

import { NavigationItem } from '../declarations'
import { getParam } from './utils'

// ***
// DEFAULTS
// ***
const appConfig = ref()

nextTick(() => {
  if (!getActivePinia()) {
    console.warn('Waiting for Pinia to be initialized...')
  } else {
    appConfig.value = useAppConfigStore()
  }
})

const navigationStructureManager: ComputedRef<NavigationItem[]> = computed(() => [
  {
    id: 'home',
    label: 'Homepage',
    icon: 'bi-house-fill',
    route: {
      name: 'managerOverview',
    },
  },
  {
    id: 'messages',
    label: 'Messages',
    icon: 'bi-envelope-fill',
    submenu: [
      {
        id: 'my-messages',
        label: 'My messages',
        route: {
          name: 'managerMyMessagesList',
        },
      },
      {
        id: 'messages-deleted',
        label: 'Deleted messages',
        route: {
          name: 'managerDeletedMessagesList',
        },
      },
      {
        id: 'mailbox-management',
        label: 'Mailbox management',
        route: {
          name: 'managerMailboxManagement',
        },
      },
    ],
  },
  {
    id: 'persons',
    label: 'Persons',
    icon: 'bi-people-fill',
    submenu: [
      {
        id: 'persons-partners',
        label: 'Partners',
        legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/socios`,
      },
      {
        id: 'persons-athletes',
        label: 'Athletes',
        legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/jugadores`,
      },
      {
        id: 'persons-users',
        label: 'Users',
        legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/usuarios`,
      },
    ],
  },
  {
    id: 'clubs',
    label: 'Teams',
    icon: 'bi-shield-shaded',
    ...(appConfig.value?.$state.featureFlags.newTeamsView.enabled
      ? { route: { name: 'teamsList' } }
      : { legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/equipos` }
    ),
  },
  {
    id: 'sponsors',
    label: 'Sponsors',
    icon: 'bi-award-fill',
    legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/patrocinadores`,
  },
  {
    id: 'campaigns',
    label: 'Campaigns',
    icon: 'bi-megaphone-fill',
    legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/items`,
  },
  {
    id: 'bookings',
    label: 'Bookings',
    icon: 'bi-calendar-event-fill',
    isFeature: true,
    submenu: [
      {
        id: 'booking-calendar',
        label: 'Booking calendars',
        route: {
          name: 'managerBookingCalendars',
        },
      },
      {
        id: 'booking-agenda',
        label: 'Agenda',
        route: {
          name: 'managerBookingAgenda',
        },
      },
      {
        id: 'booking-availability',
        label: 'Availability',
        route: {
          name: 'managerBookingAvailability',
        },
      },
      {
        id: 'booking-pricing-policies',
        label: 'Pricing policies',
        route: {
          name: 'managerBookingPricingPolicies',
        },
      },
      {
        id: 'booking-link-groups',
        label: 'Link groups',
        route: {
          name: 'managerBookingLinkGroups',
        },
      },
      {
        id: 'booking-connected-calendars',
        label: 'Connected calendars',
        route: {
          name: 'managerConnectedCalendars',
        },
      },
    ],
  },
  {
    id: 'access-control',
    label: 'Access control',
    icon: 'bi-shop-window',
    legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/dashboard`,
  },
])

export default navigationStructureManager
