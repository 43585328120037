import { navigateToLegacy } from '@libs/fsc/utils/navigateToLegacy'

import { NavigationItem } from '../declarations'
import { getParam } from './utils'

export { default as navigationStructureManager } from './manager'
export { default as navigationStructureGuardian } from './guardian'
export { default as navigationStructureMember } from './member'

export const lowerNavigation: NavigationItem[] = [
  {
    id: 'administration',
    label: 'Administration',
    submenu: [
      {
        id: 'admin-plans',
        label: 'Plans',
        legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/facturacion`,
      },
      {
        id: 'admin-settings',
        label: 'Settings',
        legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/ajustes`,
      },
      {
        id: 'admin-fee',
        label: 'Fees',
        legacyRoute: ({ router }) => `/clubes/${getParam(router, 'clubHash')}/cargos`,
      },
    ],
  },
  {
    id: 'support',
    label: 'Support',
    action: () => {},
  },
]
