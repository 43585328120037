import { ref, reactive } from 'vue'
import { defineStore } from 'pinia'
import { featureFlags } from '@core/config'

export const useAppConfigStore = defineStore('appConfig', () => {
  const isDarkMode = ref<boolean>(false)
  const isStagingBannerHidden = ref<boolean>(false)

  return {
    isDarkMode,
    isStagingBannerHidden,
    featureFlags: reactive({ ...featureFlags }),
  }
})
