import { EndpointConfig } from '@core/store/apiRequest/declarations'

export const patchUserChangePassword: EndpointConfig = {
  storeKey: 'patchUserChangePassword',
  url: '/api/users/change-password',
}

export const postUserPasswordResetConfirmConfig: EndpointConfig = {
  storeKey: 'postUserPasswordResetConfirm',
  url: '/api/users/password-reset/confirm',
}

export const postUserPasswordResetRequestConfig: EndpointConfig = {
  storeKey: 'postUserPasswordResetRequest',
  url: '/api/users/password-reset/request',
}

export const postUserPasswordResetValidateConfig: EndpointConfig = {
  storeKey: 'postUserPasswordResetValidate',
  url: '/api/users/password-reset/validate',
}

export const getUserProfile: EndpointConfig = {
  storeKey: 'getUserProfile',
  url: '/api/profile',
}
