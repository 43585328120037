import { ref, watch, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'

import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'
import { useApiMessagesStoreRequest } from '@services/Communications/request'
import { type ErrorStoreResponse } from '@services/declarations'

const ONE_SECOND_IN_MS = 1000
const AWAIT_TIME = 120 * ONE_SECOND_IN_MS
const DEBOUNCE_TIME = 1 * ONE_SECOND_IN_MS

let timeoutId: NodeJS.Timeout
let lastInvokeTime : number = 0

export function useUnreadMessages({ shouldPollUnreadMessages } = { shouldPollUnreadMessages: false }) {
  const unreadMessages = ref()
  const route = useRoute()
  const polledClubHash = ref()
  const polledUserId = ref()
  const { currentUser } = useGetLegacyPeopleGroupByClub()
  const { fetchGetUnreadMessages } = useApiMessagesStoreRequest()

  function requestUnreadMessages() {
    if (!route.params.clubHash || !currentUser.value) return
    const currentTime = Date.now()
    if (currentTime - lastInvokeTime < DEBOUNCE_TIME) return
    if (timeoutId) clearTimeout(timeoutId)

    lastInvokeTime = currentTime
    timeoutId = setTimeout(requestUnreadMessages, AWAIT_TIME)

    if (!currentUser?.value?.id) return
    fetchGetUnreadMessages({
      params: { clubHash: route.params.clubHash },
      headers: { Profile: currentUser.value.id },
    }).then(({ data }) => {
      if (!data) return
      unreadMessages.value = data.unreadMessages
    }).catch(() => {
      // TODO: HANDLE ERROR
    })
  }

  onBeforeUnmount(() => { if (timeoutId) clearTimeout(timeoutId) })

  watch(() => [route.params.clubHash, currentUser.value?.id], ([currentClubHash, currentUserHash]) => {
    if (!shouldPollUnreadMessages || !currentClubHash || !currentUserHash) return
    if (currentClubHash === polledClubHash.value || currentUserHash === polledUserId.value) return
    if (timeoutId) clearTimeout(timeoutId)
    polledClubHash.value = currentClubHash
    polledUserId.value = currentUserHash
    requestUnreadMessages()
  })

  return {
    unreadMessages,
    requestUnreadMessages,
  }
}
