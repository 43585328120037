<script setup lang="ts">
import { ref, computed, reactive } from 'vue'
import { ulid } from 'ulid'

import { apiURL } from '@core/config'
import {
  setCustomEndpoints,
  reactiveApiUrls,
  reactiveApiRecentUrls,
  resetEndpoints,
  setRecentUsedEndpoints,
} from '@core/utils/api'

import {
  CbButton,
  CbTab,
  CbTabGroup,
  CbSelect,
} from '@cluber/carabiner'
import { capitalize } from '@cluber/tools-utils'

// ***
// COMMON
// ***

const endpoints = computed(() => (Object.keys(apiURL) as Array<keyof typeof apiURL>).filter(item => item !== 'origin'))

function selectOptions(endpoint: keyof typeof apiURL) {
  return reactiveApiRecentUrls[endpoint]?.map(item => ({
    label: item,
    value: item,
  }))
  || []
}

// ***
// RECENT SELECTED
// ***

const recentSelectedMV = reactive<Record<string, never> | Record<keyof typeof apiURL, string>>({})

function setCustomEndpointsFromRecentSelected(endpoint: keyof typeof apiURL, value: string) {
  if (!recentSelectedMV[endpoint]) {
    Object.assign(recentSelectedMV, { [endpoint]: value })
  } else {
    recentSelectedMV[endpoint] = value
  }

  setCustomEndpoints(endpoint, recentSelectedMV[endpoint])
}
// ***
// RESET
// ***

const resetStatus = ref('initial')

function resetCustomEndpoints(endpoint: keyof typeof apiURL) {
  setCustomEndpoints(endpoint, apiURL[endpoint])
  resetStatus.value = ulid()
}

</script>

<template>
  <div>
    <h2 class="mb-5 underline font-bold">
      Endpoints
    </h2>
    <CbButton
      mode="outline"
      color="secondary"
      size="sm"
      class="w-full mb-4"
      @click="() => {
        resetEndpoints()
        resetStatus = ulid()
      }"
    >
      Reset all endpoints
    </CbButton>
    <CbTabGroup>
      <CbTab
        value="settings"
        title="Current"
      >
        <template
          v-for="endpoint in endpoints"
          :key="endpoint"
        >
          <div class="mb-4 min-w-96">
            <FormKit
              :key="resetStatus"
              :label="capitalize(endpoint)"
              type="text"
              class="relative"
              :value="reactiveApiUrls[endpoint]"
              @blur="() => setRecentUsedEndpoints(endpoint, reactiveApiUrls[endpoint])"
              @input="(value) => setCustomEndpoints(endpoint, value)"
            >
              <template #suffixIcon>
                <div
                  class="absolute right-[3.3rem] cursor-pointer hover:bg-primary-100 active:bg-primary-200 rounded-xs px-1"
                  @click.stop="() => resetCustomEndpoints(endpoint)"
                  @keydown.enter="() => resetCustomEndpoints(endpoint)"
                >
                  <i class="bi bi-arrow-counterclockwise" />
                </div>
              </template>
            </FormKit>
          </div>
        </template>
      </CbTab>
      <CbTab
        value="user-edit"
        title="Recent"
      >
        <template
          v-for="endpoint in endpoints"
          :key="endpoint"
        >
          <div class="mb-4 min-w-96">
            <CbSelect
              :id="`${endpoint}_recentSelect`"
              :label="capitalize(endpoint)"
              :options="selectOptions(endpoint)"
              class="w-full"
              menu-width="20rem"
              :searchable="selectOptions(endpoint).length > 4"
              :placeholder="!selectOptions(endpoint).length ? 'No recent endpoints used' : 'Select one...'"
              :model-value="recentSelectedMV[endpoint]"
              @update:model-value="(value) => setCustomEndpointsFromRecentSelected(endpoint, value)"
            />
          </div>
        </template>
      </CbTab>
    </CbTabGroup>
    <hr class="mt-5">
  </div>
</template>
