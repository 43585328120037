import { ApiPublicPortalComponentContent } from '@portal/modules/PublicWebsite/declarations'

export const componentsList: ApiPublicPortalComponentContent[] = [
  {
    '@id': '/api/clubs/2/portal/components/2',
    '@type': 'PortalComponent',
    id: 2,
    core: true,
    name: 'tickets',
    type: 'link',
    properties: {
      href: '/clubes/2a5fb7dc1f43ddf097a8d44d7b926307cafb1daa/portal/tickets',
      image: 'https://s3.eu-west-1.amazonaws.com/assets.cluber/portal/tickets.svg',
    },
    enabled: false,
    order: 1,
    highlighted: false,
  },
  {
    '@id': '/api/clubs/2/portal/components/3072',
    '@type': 'PortalComponent',
    id: 3072,
    core: true,
    name: 'campaigns',
    type: 'link',
    properties: {
      href: '/clubes/2a5fb7dc1f43ddf097a8d44d7b926307cafb1daa/portal/campanas',
      image: 'https://s3.eu-west-1.amazonaws.com/assets.cluber/portal/speaker.svg',
    },
    enabled: true,
    order: 2,
    highlighted: false,
  },
  {
    '@id': '/api/clubs/2/portal/components/6142',
    '@type': 'PortalComponent',
    id: 6142,
    core: true,
    name: 'member.registration',
    type: 'link',
    properties: {
      href: '/clubes/2a5fb7dc1f43ddf097a8d44d7b926307cafb1daa/alta/socios',
      image: 'https://s3.eu-west-1.amazonaws.com/assets.cluber/portal/cardID.svg',
    },
    enabled: false,
    order: 3,
    highlighted: false,
  },
  {
    '@id': '/api/clubs/2/portal/components/9212',
    '@type': 'PortalComponent',
    id: 9212,
    core: true,
    name: 'inscriptions',
    type: 'link',
    properties: {
      href: '/clubes/2a5fb7dc1f43ddf097a8d44d7b926307cafb1daa/inscripcion',
      image: 'https://s3.eu-west-1.amazonaws.com/assets.cluber/portal/inscription.svg',
    },
    enabled: true,
    order: 4,
    highlighted: false,
  },
]

export const getPublicPortalComponentsMock = {
  '@context': '/api/contexts/PortalComponent',
  '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d/portal/components',
  '@type': 'hydra:Collection',
  'hydra:totalItems': 4,
  'hydra:member': componentsList,
  'hydra:view': {
    '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d/portal/components?size=10',
    '@type': 'hydra:PartialCollectionView',
  },
}
