import { http, HttpResponse, type RequestHandler } from 'msw'
import { getApiRoute } from '@core/utils/api'
import { patchUserChangePassword, getUserProfile } from '@core/modules/User/api'
import { profileMock } from '@mocks/profile-mock'

export const patchUserChangePasswordHandler: RequestHandler = http.patch(
  getApiRoute(patchUserChangePassword.url),
  () => HttpResponse.json({}),
)

export const getUserProfileHandler: RequestHandler = http.get(
  getApiRoute(getUserProfile.url),
  () => HttpResponse.json(JSON.stringify(profileMock)),
)
