<script setup lang="ts">
import { inject, onMounted } from 'vue'
import { featureFlags } from '@core/config'
import { CbSwitch } from '@cluber/carabiner'

import { appConfigInject } from '../declarations'

// ***
// DEFAULTS
// ***
const devToolsState = inject(appConfigInject)

function toggleFeatureFlags(featureFlagsUpdated: typeof featureFlags) {
  if (!devToolsState) return
  // Update darkmode state for whole app
  devToolsState.appConfig.$patch({ featureFlags: featureFlagsUpdated })

  // Update darkmode in localStorage for future loadings
  devToolsState.appConfigStorage.value.featureFlags = featureFlagsUpdated
}

onMounted(() => toggleFeatureFlags(devToolsState?.appConfigStorage.value.featureFlags || featureFlags))

</script>

<template>
  <div class="w-48">
    <h2 class="mb-5">
      Feature flags
    </h2>
    <template
      v-for="flag in Object.keys(featureFlags)"
      :key="flag"
    >
      <CbSwitch
        :model-value="devToolsState.appConfig.$state.featureFlags[flag as keyof typeof featureFlags].enabled"
        class="mt-5"
        name="switch-input"
        @update:model-value="(value) => {
          toggleFeatureFlags({
            ...devToolsState?.appConfig.$state.featureFlags || featureFlags,
            [flag]: {
              name: featureFlags[flag as keyof typeof featureFlags].name,
              enabled: value,
            },
          })
        }"
      >
        {{ featureFlags[flag as keyof typeof featureFlags].name }}
      </CbSwitch>
      <hr class="mt-5">
    </template>
  </div>
</template>
