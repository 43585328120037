import { type RouteRecordRaw } from 'vue-router'
import { type ModuleRoute } from '@core/declarations/router'
import { UserKind } from '@libs/fsc/types/user'

const MessagesOverview = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesOverview.vue')
const MessagesList = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesList/MessagesList.vue')
const MessagesListDeleted = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesListDeleted/MessagesListDeleted.vue')
const MessagesListArchived = () => import('@dashboard/modules/Messages/views/MessagesOverview/MessagesListArchived/MessagesListArchived.vue')
const MessagesThread = () => import('@dashboard/modules/Messages/views/MessagesThread/MessagesThread.vue')
const MailboxManagement = () => import('@dashboard/modules/Messages/views/MailboxManagement/MailboxManagement.vue')

type MessageRoutesUserKind = UserKind.Guardian | UserKind.Member | UserKind.Manager

const routeArchivedByRole = {
  [UserKind.Guardian]: 'guardianArchivedMessagesList',
  [UserKind.Member]: 'memberArchivedMessagesList',
  [UserKind.Manager]: 'managerArchivedMessagesList',
}

const routeMessagesByRole = {
  [UserKind.Guardian]: 'guardianMyMessagesList',
  [UserKind.Member]: 'memberMyMessagesList',
  [UserKind.Manager]: 'managerMyMessagesList',
}

const pathMyMessagesByRole = {
  [UserKind.Guardian]: '/dashboard/guardian/:clubHash/messages/my-messages',
  [UserKind.Member]: '/dashboard/member/:clubHash/messages/my-messages',
  [UserKind.Manager]: '/dashboard/manager/:clubHash/messages/my-messages',
}

function generateSharedRoutes(role: MessageRoutesUserKind): RouteRecordRaw[] {
  return ([
    {
      path: pathMyMessagesByRole[role],
      name: routeMessagesByRole[role],
      component: MessagesList,
      meta: {
        tabTitle: 'My messages',
      },
    },
    {
      path: ':clubHash/archived-messages',
      name: routeArchivedByRole[role],
      component: MessagesListArchived,
      meta: {
        tabTitle: 'Archived messages',
      },
    },
  ])
}

function generateMessageThread(role: MessageRoutesUserKind): RouteRecordRaw {
  const routeByRole = {
    [UserKind.Guardian]: 'guardianMessageThread',
    [UserKind.Member]: 'memberMessageThread',
    [UserKind.Manager]: 'managerMessageThread',
  }

  return ({
    path: ':clubHash/messages/thread/:threadHash',
    name: routeByRole[role],
    component: MessagesThread,
    meta: {
      tabTitle: 'Message thread',
    },
  })
}

export const guardian: ModuleRoute[] = [
  {
    path: '',
    name: 'GuardianMessages',
    component: MessagesOverview,
    children: [
      ...generateSharedRoutes(UserKind.Guardian),
      generateMessageThread(UserKind.Guardian),
    ],
  },
]

export const member: ModuleRoute[] = [
  {
    path: '',
    name: 'MemberMessages',
    component: MessagesOverview,
    children: [
      ...generateSharedRoutes(UserKind.Member),
      generateMessageThread(UserKind.Member),
    ],
  },
]

export const manager: ModuleRoute[] = [
  {
    path: '',
    name: 'ManagerMessages',
    component: MessagesOverview,
    children: [
      ...generateSharedRoutes(UserKind.Manager),
      generateMessageThread(UserKind.Manager),
      {
        path: ':clubHash/messages/mailbox-management',
        name: 'managerMailboxManagement',
        component: MailboxManagement,
        meta: {
          tabTitle: 'Mailbox management',
        },
      },
      {
        path: ':clubHash/messages/deleted-messages',
        name: 'managerDeletedMessagesList',
        component: MessagesListDeleted,
        meta: {
          tabTitle: 'Deleted messages',
        },
      },
    ],
  },
]

export const messagesRedirects : RouteRecordRaw[] = [
  // MESSAGES LIST
  {
    path: `/dashboard/${UserKind.Manager}/messages/:clubHash/my-messages`,
    redirect: to => ({ path: `/dashboard/${UserKind.Manager}/${to.params.clubHash as string}/messages/my-messages` }),
  },
  {
    path: `/dashboard/${UserKind.Member}/messages/:clubHash/my-messages`,
    redirect: to => ({ path: `/dashboard/${UserKind.Member}/${to.params.clubHash as string}/messages/my-messages` }),
  },
  {
    path: `/dashboard/${UserKind.Guardian}/messages/:clubHash/my-messages`,
    redirect: to => ({ path: `/dashboard/${UserKind.Guardian}/${to.params.clubHash as string}/messages/my-messages` }),
  },
  // THREAD DETAIL
  {
    path: `/dashboard/${UserKind.Manager}/messages/:clubHash/thread/:threadHash`,
    redirect: to => ({ path: `/dashboard/${UserKind.Manager}/${to.params.clubHash as string}/messages/thread/${to.params.threadHash as string}` }),
  },
  {
    path: `/dashboard/${UserKind.Member}/messages/:clubHash/thread/:threadHash`,
    redirect: to => ({ path: `/dashboard/${UserKind.Member}/${to.params.clubHash as string}/messages/thread/${to.params.threadHash as string}` }),
  },
  {
    path: `/dashboard/${UserKind.Guardian}/messages/:clubHash/thread/:threadHash`,
    redirect: to => ({ path: `/dashboard/${UserKind.Guardian}/${to.params.clubHash as string}/messages/thread/${to.params.threadHash as string}` }),
  },
]
