import type {
  EndpointConfig,
  AllowedRequestConfigProperties,
  ErrorStoreResponse,
} from '@core/store/apiRequest/declarations'

import { type PiniaCustomProperties } from 'pinia'

import { StoreSendRequestError } from './errorHandling'

// ***
// DEFAULTS
// ***
const defaultCooldownTime = 0
const lastRequestTimes = new Map<string, number>()

// ***
// TYPES
// ***
export type RequestOptions = {
  cooldownTime?: number
  enableWatch?: boolean
}

// ***
// MAIN FUNCTION
// ***

export function createRateLimitedRequest<
  RequestConfig,
  SuccessResponse,
  ErrorResponse = ErrorStoreResponse,
>({
  sendRequest,
  watchRequest,
  endpoint,
  requestConfig,
  serviceName,
  cooldownTime = defaultCooldownTime,
  enableWatch = true,
}: {
  sendRequest: PiniaCustomProperties['sendRequest'];
  watchRequest?: PiniaCustomProperties['watchRequest'];
  endpoint: EndpointConfig;
  requestConfig?: AllowedRequestConfigProperties<RequestConfig>;
  serviceName: string;
  cooldownTime?: number;
  enableWatch?: boolean;
}) {
  const endpointKey = endpoint.storeKey

  return new Promise<{ data: SuccessResponse, totalItems?: number }>((resolve, reject) => {
    let closeSubscription: (() => void) | undefined

    if (enableWatch && watchRequest) {
      const subscription = watchRequest<SuccessResponse, ErrorResponse>(endpoint, {
        onSuccess: (data, totalItems) => {
          closeSubscription?.()

          const isNestedResponse = typeof data === 'object' && data !== null && 'data' in data
          const responseData = (isNestedResponse ? data.data : data) as SuccessResponse

          resolve({ data: responseData, totalItems })
        },
        onError: (error?: ErrorResponse) => {
          closeSubscription?.()
          reject(error)
        },
      })

      closeSubscription = subscription.closeSubscription
    }

    // Rate-limiting logic
    const now = Date.now()
    const lastTime = lastRequestTimes.get(endpointKey) || 0

    if (now - lastTime < cooldownTime) {
      // eslint-disable-next-line no-console
      console.warn('Request blocked due to cooldown time', endpoint.storeKey, endpoint.url)
      return
    }

    lastRequestTimes.set(endpointKey, now)

    try {
      sendRequest({
        endpointConfig: endpoint,
        requestConfig: requestConfig || {},
      })
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error)
      throw new StoreSendRequestError({
        message: errorMessage,
        caller: endpoint.storeKey,
        service: serviceName,
        ...(errorMessage && { error: errorMessage }),
      })
    }
  })
}
