<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  type PropType,
} from 'vue'

import { routes, type ZohoFormRoute } from './routes'

// ***
// DEFAULTS
// ***
const props = defineProps({
  formRoute: {
    type: String as PropType<ZohoFormRoute>,
    required: true,
  },
  params: {
    type: Object as PropType<Record<string, string>>,
    default: undefined,
  },
})

// ***
// PROCESSED PARAMS
// ***
const processedParams = computed(() => ({
  ...props.params,
  zf_rszfm: '1', // Necessary to resize form on load
}))

// ***
// IFRAME URL
// ***
const formSource = computed(() => {
  const query = Object.entries(processedParams.value).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')
  return `${routes[props.formRoute]}?${query}`
})

// ***
// LISTEN TO FRAME CHANGES
// ***
const zohoFrame = ref<HTMLElement | undefined>()

function onMessageSent(evt : { data: string }) {
  const formInfo = evt.data.split('|')
  if (formInfo.length < 2 || !zohoFrame.value) return

  const zohoFormOffset = 15
  const formHeight = `${parseInt(formInfo[1], 10) + zohoFormOffset}px`
  const iframe = zohoFrame.value
  iframe.style.height = formHeight
}

onMounted(() => window.addEventListener('message', onMessageSent))
onUnmounted(() => window.removeEventListener('message', onMessageSent))

</script>

<template>
  <iframe
    ref="zohoFrame"
    class="w-full"
    title="zohoFrame"
    :src="formSource"
  />
</template>
