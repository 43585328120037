import { NavigationItem } from '../declarations'
import { getParam } from './utils'

const navigationStructureMember: NavigationItem[] = [
  {
    id: 'home',
    label: 'Homepage',
    icon: 'bi-house-fill',
    legacyRoute: ({ router, currentUser }) => `/socios/${currentUser?.id}/clubes/${getParam(router, 'clubHash')}/area-privada`,
  },
  {
    id: 'identification',
    label: 'My ID',
    icon: 'bi-person-vcard-fill',
    legacyRoute: ({ router, currentUser }) => `/socios/${currentUser?.id}/clubes/${getParam(router, 'clubHash')}/carnet`,
  },
  {
    id: 'messages',
    label: 'Messages',
    icon: 'bi-envelope-fill',
    submenu: [
      {
        id: 'my-messages',
        label: 'My messages',
        route: {
          name: 'memberMyMessagesList',
        },
      },
    ],
  },
  {
    id: 'payments',
    label: 'Payments',
    icon: 'bi-bank2',
    legacyRoute: ({ router, currentUser }) => `/socios/${currentUser?.id}/clubes/${getParam(router, 'clubHash')}/cargos`,
  },
  {
    id: 'support',
    label: 'Support',
    icon: 'bi-life-preserver',
    action: () => { },
  },
]

export default navigationStructureMember
