import {
  BadgeMode,
  type AllowedBadgeModes,
} from '@cluber/carabiner'

import {
  ExcludeExternalRole,
  RoleKind,
  ExcludeExternalKind,
  AllowedUserKind,
} from '@services/User/declarations'

export function transformUserKindsToRoles(userKind: ExcludeExternalKind): ExcludeExternalRole {
  const roles: Record<ExcludeExternalKind, ExcludeExternalRole> = {
    manager: RoleKind.Manager,
    member: RoleKind.Member,
    guardian: RoleKind.Guardian,
    player: RoleKind.Player,
    admin: RoleKind.Admin,
  }

  return roles[userKind]
}

export function imageByRole(role: ExcludeExternalRole): string {
  const images = {
    [RoleKind.Guardian]: 'https://s3.eu-west-1.amazonaws.com/assets.cluber.es/images/shared/guardian.svg',
    [RoleKind.Member]: 'https://s3.eu-west-1.amazonaws.com/assets.cluber.es/images/shared/member.svg',
    [RoleKind.Manager]: 'https://s3.eu-west-1.amazonaws.com/assets.cluber.es/images/shared/manager.svg',
    [RoleKind.Player]: 'https://s3.eu-west-1.amazonaws.com/assets.cluber.es/images/shared/player.svg',
    [RoleKind.Admin]: 'https://s3.eu-west-1.amazonaws.com/assets.cluber.es/images/shared/admin.svg',
  }

  return images[role]
}

export function badgeByRole(role: ExcludeExternalRole): AllowedBadgeModes {
  const mode = {
    [RoleKind.Guardian]: BadgeMode.Secondary,
    [RoleKind.Manager]: BadgeMode.Primary,
    [RoleKind.Member]: BadgeMode.Success,
    [RoleKind.Player]: BadgeMode.Success,
    [RoleKind.Admin]: BadgeMode.Accent,
  }

  return mode[role]
}

export function translationKeyByRole(role: ExcludeExternalRole): string {
  const translationKey = {
    [RoleKind.Guardian]: 'Guardian',
    [RoleKind.Manager]: 'Manager',
    [RoleKind.Member]: 'Member',
    [RoleKind.Player]: 'Player',
    [RoleKind.Admin]: 'Admin',
  }

  return translationKey[role]
}

export function matchRoleToLegacyMagicNumber(role: Exclude<AllowedUserKind, 'admin'>) {
  const userKinds: Record<typeof role, number> = {
    manager: 1,
    member: 2,
    player: 3,
    guardian: 4,
    external: 5,
  }

  return userKinds[role]
}
