import { http, HttpResponse } from 'msw'

import { getClubPublicPortalComponents, postPublicPortalComponent } from '@portal/modules/PublicWebsite/api'
import { getApiRoute } from '@core/utils/api'

import { getPublicPortalComponentsMock } from './mockData'

type UrlParams = { params: { clubHash: string } }

export const getComponentsListHandler = http.get(
  getApiRoute<UrlParams>(getClubPublicPortalComponents.url, { params: { clubHash: '*' } }, 'main'),
  ({ request, params, cookies }) => HttpResponse.json(getPublicPortalComponentsMock),
)

export const postComponent = http.post(
  getApiRoute<UrlParams>(postPublicPortalComponent.url, { params: { clubHash: '*' } }, 'main'),
  ({ request, params, cookies }) => HttpResponse.json({}),
)
