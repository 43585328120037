import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { watchOnce } from '@vueuse/core'
import { changeLocale } from '@formkit/vue'

import { useApiAuthStore } from '@core/modules/Auth/store'
import { useApiUserStore } from '@core/modules/User/store'
import { getUserProfile } from '@core/modules/User/api'
import {
  type ClubResourceWithRoles,
} from '@dashboard/modules/ProfileSelector/declarations'

import { isJWTExpired } from '@core/store/apiRequest/apiRequest'

const formkitMatchingLocale = {
  es: ['es-ES'],
  en: ['gl-ES', 'eu-ES', 'en-US'],
  ca: ['ca-ES'],
}

function setFormkitLocale(locale: string) {
  const matchingLocale = Object.keys(formkitMatchingLocale).find(item => (
    formkitMatchingLocale[item as keyof typeof formkitMatchingLocale].includes(locale)
  )) || 'en'
  changeLocale(matchingLocale)
}

export default function useGetClubLocale() {
  const route = useRoute()
  const localeIsSet = ref(false)
  const { locale } = useI18n()
  const { $state: authState } = useApiAuthStore()
  const {
    sendRequest: sendUserProfileRequest,
    $state: userProfileState,
  } = useApiUserStore()
  function extractLocaleFromClub(clubs: ClubResourceWithRoles[]) {
    const club = clubs?.find((item) => {
      const clubHash = item.club.substring(item.club.lastIndexOf('/') + 1, item.club.length)
      if (!route.params.clubHash) return route.query?.from?.includes(clubHash)
      return clubHash === route.params.clubHash
    })

    if (process.env.NODE_ENV === 'CI') return 'en-US'

    return club?.locale || 'es-ES'
  }

  function setLocale() {
    if (!userProfileState.getUserProfile) {
      const authToken = authState.postCreateUserToken?.token
      if (!authToken || isJWTExpired(authToken)) return
      sendUserProfileRequest({
        endpointConfig: getUserProfile,
      })
    } else {
      locale.value = extractLocaleFromClub(userProfileState.getUserProfile.clubs)
      setFormkitLocale(locale.value)
    }
  }

  watchOnce(() => userProfileState.getUserProfile?.clubs, (current) => {
    if (current?.length && !localeIsSet.value && locale.value) {
      localeIsSet.value = true
      locale.value = extractLocaleFromClub(current)
      setFormkitLocale(locale.value)
    }
  })

  return {
    setLocale,
  }
}
