import { http, HttpResponse, type RequestHandler } from 'msw'
import { postCreateUserToken } from '@services/Auth/api'
import { getApiRoute } from '@core/utils/api'
import authMock from '@mocks/auth-mock'

export const postTokenHandler: RequestHandler = http.post(
  getApiRoute(postCreateUserToken.url),
  () => HttpResponse.json(authMock),
)

export default [
  postTokenHandler,
]
