<script setup lang="ts">
import { type PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import LegacyLink from '@dashboard/components/LegacyLink/LegacyLink.vue'
import { useGetLegacyPeopleGroupByClub } from '@dashboard/modules/ProfileSelector/composables'

import { type NavigationItem } from '../declarations'

import NavItemBadge from '../NavItemBadge/NavItemBadge.vue'

const { t } = useI18n()
const router = useRouter()
const { currentUser } = useGetLegacyPeopleGroupByClub()

defineProps({
  navItem: {
    type: Object as PropType<NavigationItem>,
    required: true,
  },
  isActive: {
    type: Boolean,
  },
  submenuItems: {
    type: Array as PropType<NavigationItem[]>,
    required: true,
  },
})

defineEmits([
  'toggle',
])

// ***
// ITEM LABEL
// ***
function getItemLabelTranslation(item: NavigationItem) {
  return item.id.includes('subsidies') ? item.label : t(item.label)
}

</script>

<template>
  <li
    class="flex flex-col"
    @click="$emit('toggle', navItem.id)"
    @keydown.enter="$emit('toggle', navItem.id)"
  >
    <div
      :class="[
        'flex items-center hover:bg-base-300 dark:hover:bg-base-500 cursor-pointer w-full p-3',
        { 'bg-base-200 dark:bg-primary-300': isActive },
      ]"
    >
      <span
        v-if="navItem.icon"
        :class="`bi ${navItem.icon} mr-3`"
        data-testid="navitem-prefix-icon"
      />
      <p><slot /></p>
      <NavItemBadge
        class="ml-auto"
        :badge="navItem.badge"
      />
      <span
        :class="[
          'bi text-xs',
          navItem.badge ? 'mx-3' : 'ml-auto mr-3',
          isActive ? 'bi-caret-up-fill' : 'bi-caret-down-fill',
        ]"
      />
    </div>
    <ul
      :class="[
        'overflow-hidden transition-all duration-300 text-black dark:text-white dark:bg-base-700',
        isActive ? 'max-h-96' : 'max-h-0',
      ]"
      @click.stop.prevent
    >
      <li
        v-for="item in submenuItems"
        :key="item.id"
        class="flex w-full hover:bg-base-300 dark:hover:bg-base-500 cursor-pointer"
      >
        <RouterLink
          v-if="item.route"
          class="w-full p-3 pl-5"
          :to="item.route"
        >
          <p>{{ getItemLabelTranslation(item) }}</p>
        </RouterLink>
        <LegacyLink
          v-else-if="item.legacyRoute"
          v-slot="{ navigate }"
          class="w-full"
          :to="item.legacyRoute({ router, currentUser })"
        >
          <div
            class="w-full p-3 pl-5"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <p>{{ getItemLabelTranslation(item) }}</p>
          </div>
        </LegacyLink>
        <div
          v-else
          class="w-full p-3 pl-5"
        >
          <p>{{ getItemLabelTranslation(item) }}</p>
        </div>
      </li>
    </ul>
  </li>
</template>
