import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router'

import StatusPage from '@core/components/StatusPage/StatusPage.vue'
import { navigateToLegacy } from '@libs/fsc/utils/navigateToLegacy'

import { messagesRedirects } from '@dashboard/modules/Messages/routes'

import { legacyRoutes } from './legacyRedirects'

const routes: RouteRecordRaw[] = [
  ...legacyRoutes,
  ...messagesRedirects,
  {
    path: '/',
    name: 'root',
    redirect: '/dashboard/profile-selector',
  },
  {
    path: '/401',
    name: 'unathorized-user',
    component: StatusPage,
    meta: {
      errorCode: 401,
      tabTitle: 401,
    },
  },
  {
    path: '/403',
    name: 'forbidden',
    component: StatusPage,
    meta: {
      errorCode: 403,
      tabTitle: 403,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: StatusPage,
    beforeEnter: (to, from) => {
      navigateToLegacy(to.fullPath, to.query, { forceAuthToken: from.name === 'authLogin' })
      return false
    },
    meta: {
      errorCode: 404,
      tabTitle: 404,
    },
  },
  {
    path: '/500',
    name: 'internal-server-error',
    component: StatusPage,
    meta: {
      errorCode: 500,
      tabTitle: 500,
    },
  },
  {
    path: '/502',
    name: 'server-connection-issues',
    component: StatusPage,
    meta: {
      errorCode: 502,
      tabTitle: 502,
    },
  },
  {
    path: '/504',
    name: 'gateway-error',
    component: StatusPage,
    meta: {
      errorCode: 504,
      tabTitle: 504,
    },
  },
]

export default createRouter({
  history: createWebHistory(),
  routes,
})
