<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { navigateToLegacy, resolveLegacyURL } from '@libs/fsc/utils/navigateToLegacy'

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  query: {
    type: Object,
    default: undefined,
  },
})

const legacyUrl = ref()

onMounted(() => {
  legacyUrl.value = resolveLegacyURL(props.to, props.query)
})

watch(() => [props.to, props.query], () => {
  legacyUrl.value = resolveLegacyURL(props.to, props.query)
})

</script>

<template>
  <a
    data-testid="legacyLinkAnchor"
    :href="legacyUrl"
    @click.capture.prevent
  >
    <slot :navigate="() => navigateToLegacy(to, query)" />
  </a>
</template>
