import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

const ImpersonateProfile = () => import('@dashboard/modules/Admin/views/ImpersonateProfile/ImpersonateProfile.vue')

const routes: ModuleRoutes = {
  name: 'Admin',
  basePath: '/',
  roleKind: RoleKind.Admin,
  routes: [
    {
      path: 'admin/impersonate/:clubHash?',
      name: 'adminImpersonate',
      component: ImpersonateProfile,
      meta: {
        tabTitle: 'Impersonate profile',
        navigationType: 'clean',
        roleKind: RoleKind.Manager,
      },
    },

  ],
}

export default routes
