<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'

// ***
// DEFAULTS
// ***

const { get, addChangeListener, removeChangeListener } = useCookies(['cookieConsent'])
const zoho = ref<{ salesiq?: unknown }>()

// ***
// ZOHO SCRIPT INJECTION
// ***
function injectZohoScript() {
  const cookiePrefs = get<string>('cookieControlPrefs')
  if (!cookiePrefs || !cookiePrefs.includes('functionals')) return

  const zsiqscript = document.createElement('script')
  zsiqscript.setAttribute('src', 'https://salesiq.zohopublic.eu/widget?widgetcode=siq9096b9f7366fac1de2dd53a542c25d24789e7cd1f55b440776608a44b3760160')
  zsiqscript.setAttribute('type', 'text/javascript')
  zsiqscript.setAttribute('id', 'zsiqscript')
  zsiqscript.setAttribute('defer', 'true')
  document.head.appendChild(zsiqscript)

  zoho.value = zoho.value || {}; zoho.value.salesiq = zoho.value.salesiq || { widgetcode: 'siq9096b9f7366fac1de2dd53a542c25d24789e7cd1f55b440776608a44b3760160', values: {}, ready() {} }
  removeChangeListener(injectZohoScript)
}

onBeforeMount(() => {
  addChangeListener(injectZohoScript)
  injectZohoScript()
})

</script>
