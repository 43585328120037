import { EndpointConfig } from '@services/declarations'

export const getMailbox: EndpointConfig = {
  storeKey: 'getMailbox',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash',
}

export const getMailboxes: EndpointConfig = {
  storeKey: 'getMailboxes',
  url: '/api/clubs/:clubHash/communications/mailboxes',
}

export const postMailbox: EndpointConfig = {
  storeKey: 'postMailbox',
  url: '/api/clubs/:clubHash/communications/mailboxes',
}

export const patchMailbox: EndpointConfig = {
  storeKey: 'patchMailbox',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash',
}

export const deleteMailbox: EndpointConfig = {
  storeKey: 'deleteMailbox',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash',
}

export const getMailboxProfiles : EndpointConfig = {
  storeKey: 'getMailboxProfiles',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash/profiles',
}

export const postMailboxProfiles: EndpointConfig = {
  storeKey: 'postMailboxProfiles',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash/profiles',
}

export const postDuplicateMailbox: EndpointConfig = {
  storeKey: 'postDuplicateMailbox',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash/duplicate',
}

export const deleteMailboxProfile: EndpointConfig = {
  storeKey: 'deleteMailboxProfile',
  url: '/api/clubs/:clubHash/communications/mailboxes/:mailboxHash/profiles/:profileHash',
}
