export const allowMSW = import.meta.env.VITE_ALLOW_MSW === 'allow'

export const cluberLegacy = {
  baseUrl: import.meta.env.VITE_CLUBER_LEGACY_URL,
}

export const apiURL = {
  main: import.meta.env.VITE_ENDPOINT_MAIN,
  legacy: import.meta.env.VITE_ENDPOINT_LEGACY,
  legacyClient: cluberLegacy.baseUrl,
  origin: window.location.origin,
}

export const featureFlags = {
  newTeamsView: {
    name: 'New teams view',
    enabled: false,
  },
}
