import { ModuleRoutes } from '@core/declarations/router'
import { RoleKind } from '@libs/fsc/types/user'

const TeamsList = () => import('@dashboard/modules/Teams/views/TeamsList/TeamsList.vue')
const TeamDetail = () => import('@dashboard/modules/Teams/views/TeamDetail/TeamDetail.vue')

const routes: ModuleRoutes = {
  name: 'Teams',
  basePath: '/dashboard/manager/:clubHash/teams',
  roleKind: RoleKind.Manager,
  routes: [
    {
      path: '/all',
      name: 'teamsList',
      component: TeamsList,
      meta: {
        tabTitle: 'Teams',
      },
    },
    {
      path: '/:teamHash/details',
      name: 'teamDetails',
      component: TeamDetail,
      meta: {
        tabTitle: 'Team details',
      },
    },
  ],
}

export default routes
