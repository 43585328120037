import { EndpointConfig } from '@services/declarations'

export const postCreateUserToken: EndpointConfig = {
  storeKey: 'postCreateUserToken',
  url: '/api/authentication_token',
}

export const postRefreshToken: EndpointConfig = {
  storeKey: 'postRefreshToken',
  url: '/api/refresh_token',
}
